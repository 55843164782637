<template>
  <div>
    <content-numeric
      :num="this.numComputed"
      :unit="this.unitComputed"
      :symbol="this.symbolComputed"
      :content="this.contentComputed"
      :change="false"
    />
    <div v-if="lang=='ru'">
      <v-text-field
              v-if="numId"
              v-model="numRu"
              label="Количество русский"
              required
              outlined
      />
      <v-text-field
              v-if="unitId"
              v-model="unitRu"
              label="Единицы русский"
              required
              outlined
      />
      <v-text-field
              v-if="symbolId"
              v-model="symbolRu"
              label="Символ русский"
              required
              outlined
      />
      <v-text-field
              v-if="contentId"
              v-model="contentRu"
              label="Контент русский"
              required
              outlined
      />
    </div>
    <div v-if="lang=='en-US'">
      <v-text-field
              v-if="numId"
              v-model="numEng"
              label="Количество русский"
              required
              outlined
      />
      <v-text-field
              v-if="unitId"
              v-model="unitEng"
              label="Единицы русский"
              required
              outlined
      />
      <v-text-field
              v-if="symbolId"
              v-model="symbolEng"
              label="Символ русский"
              required
              outlined
      />
      <v-text-field
              v-if="contentId"
              v-model="contentEng"
              label="Текст английский"
              required
              outlined
      />
    </div>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>

<script>
import ContentNumeric from '@/components/ui/ContentNumeric'

export default {
  name: 'ChangeContentNumeric',
  components: { ContentNumeric },
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'numRu': content_to_change['num']['value_rus'],
      'unitRu': content_to_change['unit']['value_rus'],
      'symbolRu': content_to_change['symbol']['value_rus'],
      'contentRu': content_to_change['content']['value_rus'],

      'numEng': content_to_change['num']['value_eng'],
      'unitEng': content_to_change['unit']['value_eng'],
      'symbolEng': content_to_change['symbol']['value_eng'],
      'contentEng': content_to_change['content']['value_eng']
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    numId () {
      return this.$store.getters['contentChanges/content_to_change']['num']['id_content']
    },
    unitId () {
      return this.$store.getters['contentChanges/content_to_change']['unit']['id_content']
    },
    symbolId () {
      return this.$store.getters['contentChanges/content_to_change']['symbol']['id_content']
    },
    contentId () {
      return this.$store.getters['contentChanges/content_to_change']['content']['id_content']
    },
    numComputed() {
      if (this.$i18n.locale == 'ru') { return this.numRu }
      return this.numEng
    },
    unitComputed() {
      if (this.$i18n.locale == 'ru') { return this.unitRu }
      return this.unitEng
    },
    symbolComputed() {
      if (this.$i18n.locale == 'ru') { return this.symbolRu }
      return this.symbolEng
    },
    contentComputed() {
      if (this.$i18n.locale == 'ru') { return this.contentRu }
      return this.contentEng
    }
  },
  methods: {
    saveChanges () {
      let valueList = []
      if (this.numId) {
        valueList.push({
          'value_rus': this.numRu === undefined ? '' : this.numRu,
          'value_eng': this.numEng === undefined ? '' : this.numEng,
          'id_content': this.numId
        })
      }
      if (this.unitId) {
        valueList.push({
          'value_rus': this.unitRu === undefined ? '' : this.unitRu,
          'value_eng': this.unitEng === undefined ? '' : this.unitEng,
          'id_content': this.unitId
        })
      }
      if (this.symbolId) {
        valueList.push({
          'value_rus': this.symbolRu === undefined ? '' : this.symbolRu,
          'value_eng': this.symbolEng === undefined ? '' : this.symbolEng,
          'id_content': this.symbolId
        })
      }
      if (this.contentId) {
        valueList.push({
          'value_rus': this.contentRu === undefined ? '' : this.contentRu,
          'value_eng': this.contentEng === undefined ? '' : this.contentEng,
          'id_content': this.contentId
        })
      }
      console.log('valueList')
      console.log(valueList)
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES', {
        'valueList': valueList
      })
    }
  }
}
</script>

<style scoped>

</style>
