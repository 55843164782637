<template>
  <button class="menu-item"
          :class="{'is-active':isActive? isActive():null}"
  @click="action"
  :title="title"
  >
    <svg height="20" width="20" class="remix" >
      <use :xlink:href="`${remixiconUrl}#ri-${icon}`"></use>
    </svg>
  </button>
</template>
<script>
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg'
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    isActive: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      remixiconUrl
    }
  }
}
</script>
<style lsng="scss">

.menu-item{
  background: transparent!important;
  border: none!important;
  border-radius: 0.4rem!important;
  color: #fff!important;
  cursor: pointer!important;
  height: 1.75rem!important;
  padding: 0.25rem!important;
  margin-right: 0.25rem!important;
  width: 1.75rem!important;

  svg {
    fill: currentColor!important;
    height: 100%!important;
    width: 100%!important;
  }
}
.is-active {
    background-color: #3d3c3c!important;
}

</style>