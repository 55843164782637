<template>
  <div class="bg-grey-900 py-5 flex">
    <template v-for="(item, index) in items">
      <div
        class="divider"
        v-if="item.type === 'divider'"
        :key="`divider${index}`"
      />
      <menu-item
        v-else
        :key="index"
        v-bind="item"
      />
    </template>
  </div>
</template>
<script>
import MenuItem from "@/components/ui/ChangeContent/ChangeHtml/MenuItem.vue";

export default {
  name: "Tiptap",
  components: { MenuItem },
  props: {
    editor: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      items: [
        {
          icon:'bold',
          title:'Полужирный',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold')
        },
        {
          icon:'italic',
          title:'Курсив',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic')
        },
        {
          icon:'strikethrough',
          title:'Зачеркнутый',
          action: () => this.editor.chain().focus().toggleStrike().run(),
          isActive: () => this.editor.isActive('strike')
        },
        {
          icon:'mark-pen-line',
          title:'Подчеркнуть',
          action: () => this.editor.chain().focus().toggleUnderline().run(),
          isActive: () => this.editor.isActive('underline')
        },
        {
          icon:'braces-line',
          title:'Перекрасить',
          action: () => this.editor.chain().focus().toggleSpan().run(),
          isActive: () => this.editor.isActive('span')
        },
        {
          icon:'h-1',
          title:'Заголовок 1-го уровня',
          action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 1 })
        },
        {
          icon:'h-2',
          title:'Заголовок 2-го уровня',
          action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 2 })
        },
        {
          icon:'paragraph',
          title:'Парфграф',
          action: () => this.editor.chain().focus().setParagraph().run(),
          isActive: () => this.editor.isActive('paragraph')
        },
        {
          icon:'link',
          title:'Ссылка',
          action: () => this.linkAction(),
          isActive: () => this.editor.isActive('link')
        },
        {
          icon:'list-unordered',
          title:'Лист точек',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList')
        },
        {
          icon:'list-ordered',
          title:'Порядковый лист',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList')
        },
        {
          icon:'separator',
          title:'Горизонтальная линия ',
          action: () => this.editor.chain().focus().setHorizontalRule().run()
        },
        {
          icon:'text-wrap',
          title:'Переход на новую строчку (Enter)',
          action: () => this.editor.chain().focus().setHardBreak().run()
        },
        {
          icon:'format-clear',
          title:'Очистить формат',
          action: () => this.editor.chain().focus().clearNodes().unsetAllMarks().run()
        },
        {
          icon: 'arrow-go-back-line',
          title: 'Отменить последнее действие',
          action: () => this.editor.chain().focus().undo().run()
        },
        {
          icon: 'arrow-go-forward-line',
          title: 'Отменить отмену',
          action: () => this.editor.chain().focus().redo().run()
        }
      ]
    }
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
    linkAction () {
      if (this.editor.isActive('link')){
        this.editor.chain().focus().unsetLink().run()
      }
      else
        this.setLink()
    }
  }
}
</script>

<style lang = 'scss'>
.divider {
  background-color: rgba(#fff,0.25)!important;
  height: 1.25rem!important;
  margin-left: 0.5rem!important;
  margin-right: 0.75rem!important;
  width: 1px!important;
}
</style>