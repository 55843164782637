

<template>
    <div>
        <v-combobox
            :items="mdi_keys"
            v-model="chosed_svg"
            @change="updateValue"
            label="svg иконка"
        ></v-combobox>
    </div>
</template>

<script>
import * as mdi from "@mdi/js"
export default {
  name: "ChangeSVG",
  props: ['value'],
  data () {
    let mdi_keys = Object.keys(mdi)
    mdi_keys.push('Изначальная иконка')
    return {
      start_icon: this.value,
      chosed_svg: 'Изначальная иконка',
      mdi_keys: mdi_keys
    }
  },
  methods:{
    updateValue (){
      if (this.mdi_keys.includes(this.chosed_svg)) {
        if (this.chosed_svg=='Изначальная иконка')
          {this.$emit('input', this.start_icon)}
        else {
          this.$emit('input', mdi[this.chosed_svg])} }
      else {
        this.$emit('input', this.chosed_svg)
      }
    }
  }
}
</script>
