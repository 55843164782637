<template>
  <div>
    <offer-goods-form
            :name-label="nameLabelComputed"
            :company-label="companyLabelComputed"
            :site-label="siteLabelComputed"
            :file-label="fileLabelComputed"
            :email-label="emailLabelComputed"
            :text-label="textLabelComputed"
            :region-label="regionLabelComputed"
            :contact_name-label="contact_nameLabelComputed"
            :name-svg="nameSvg"
            :company-svg="companySvg"
            :site-svg="siteSvg"
            :file-svg="fileSvg"
            :email-svg="emailSvg"
            :text-svg="textSvg"
            :region-svg="regionSvg"
            :contact_name-svg="contact_nameSvg"
            :change="false"
        />
    <div v-if="lang=='ru'">
       <v-text-field
         v-if="nameLabelId"
         v-model="nameLabelRu"
         label="Наименование русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
       <v-text-field
         v-if="nameLabelId"
         v-model="nameLabelEng"
         label="Наименование английский"
         required
         outlined
       />
    </div>
    <div v-if="nameLabelId">
      <change-s-v-g
          v-model="nameSvg">
      </change-s-v-g>
    </div>
    <div v-if="lang=='ru'">
       <v-text-field
         v-if="companyLabelId"
         v-model="companyLabelRu"
         label="Компания русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
       <v-text-field
         v-if="companyLabelId"
         v-model="companyLabelEng"
         label="Компания английский"
         required
         outlined
       />
    </div>
    <div v-if="companySvgId">
      <change-s-v-g
          v-model="companySvg">
      </change-s-v-g>
    </div>
    <div v-if="lang=='ru'">
       <v-text-field
         v-if="siteLabelId"
         v-model="siteLabelRu"
         label="Сайт русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
       <v-text-field
         v-if="siteLabelId"
         v-model="siteLabelEng"
         label="Сайт английский"
         required
         outlined
       />
    </div>
    <div v-if="siteSvgId">
      <change-s-v-g
          v-model="siteSvg">
      </change-s-v-g>
    </div>
    <div v-if="lang=='ru'">
       <v-text-field
         v-if="fileLabelId"
         v-model="fileLabelRu"
         label="Файл русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
       <v-text-field
         v-if="fileLabelId"
         v-model="fileLabelEng"
         label="Файл английский"
         required
         outlined
       />
    </div>
    <div v-if="fileSvgId">
      <change-s-v-g
          v-model="fileSvg">
      </change-s-v-g>
    </div>
    <div v-if="lang=='ru'">
       <v-text-field
         v-if="emailLabelId"
         v-model="emailLabelRu"
         label="email русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
       <v-text-field
         v-if="emailLabelId"
         v-model="emailLabelEng"
         label="email английский"
         required
         outlined
       />
    </div>
    <div v-if="emailSvgId">
      <change-s-v-g
          v-model="emailSvg">
      </change-s-v-g>
    </div>
    <div v-if="lang=='ru'">
       <v-text-field
         v-if="textLabelId"
         v-model="textLabelRu"
         label="Текст русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
       <v-text-field
         v-if="textLabelId"
         v-model="textLabelEng"
         label="Текст английский"
         required
         outlined
       />
    </div>
    <div v-if="textSvgId">
      <change-s-v-g
          v-model="textSvg">
      </change-s-v-g>
    </div>
    <div v-if="lang=='ru'">
       <v-text-field
         v-if="regionLabelId"
         v-model="regionLabelRu"
         label="Регион русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
       <v-text-field
         v-if="regionLabelId"
         v-model="regionLabelEng"
         label="Регион английский"
         required
         outlined
       />
    </div>
    <div v-if="regionSvgId">
      <change-s-v-g
          v-model="regionSvg">
      </change-s-v-g>
    </div>
    <div v-if="lang=='ru'">
       <v-text-field
         v-if="contact_nameLabelId"
         v-model="contact_nameLabelRu"
         label="Контактное лицо русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
       <v-text-field
         v-if="contact_nameLabelId"
         v-model="contact_nameLabelEng"
         label="Контактное лицо английский"
         required
         outlined
       />
    </div>
    <div v-if="contact_nameSvgId">
      <change-s-v-g
          v-model="contact_nameSvg">
      </change-s-v-g>
    </div>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>

<script>
import OfferGoodsForm from "@/components/ui/OfferGoodsForm.vue";
import ChangeSVG from "@/components/ui/ChangeContent/ChangeSVG.vue";

export default {
  name: "ChangeWorkForm",
  components: {ChangeSVG, OfferGoodsForm},
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'nameLabelRu': content_to_change['nameLabel']['value_rus'],
      'companyLabelRu': content_to_change['companyLabel']['value_rus'],
      'siteLabelRu': content_to_change['siteLabel']['value_rus'],
      'fileLabelRu': content_to_change['fileLabel']['value_rus'],
      'emailLabelRu': content_to_change['emailLabel']['value_rus'],
      'textLabelRu': content_to_change['textLabel']['value_rus'],
      'regionLabelRu': content_to_change['regionLabel']['value_rus'],
      'contact_nameLabelRu': content_to_change['contact_nameLabel']['value_rus'],

      'nameLabelEng': content_to_change['nameLabel']['value_eng'],
      'companyLabelEng': content_to_change['companyLabel']['value_eng'],
      'siteLabelEng': content_to_change['siteLabel']['value_eng'],
      'fileLabelEng': content_to_change['fileLabel']['value_eng'],
      'emailLabelEng': content_to_change['emailLabel']['value_eng'],
      'textLabelEng': content_to_change['textLabel']['value_eng'],
      'regionLabelEng': content_to_change['regionLabel']['value_eng'],
      'contact_nameLabelEng': content_to_change['contact_nameLabel']['value_eng'],

      'nameSvg': content_to_change['nameSvg']['value_rus'],
      'companySvg': content_to_change['companySvg']['value_rus'],
      'siteSvg': content_to_change['siteSvg']['value_rus'],
      'fileSvg': content_to_change['fileSvg']['value_rus'],
      'emailSvg': content_to_change['emailSvg']['value_rus'],
      'textSvg': content_to_change['textSvg']['value_rus'],
      'regionSvg': content_to_change['regionSvg']['value_rus'],
      'contact_nameSvg': content_to_change['contact_nameSvg']['value_rus']
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    nameLabelId () {
      return this.$store.getters['contentChanges/content_to_change']['nameLabel']['id_content']
    },
    companyLabelId () {
      return this.$store.getters['contentChanges/content_to_change']['companyLabel']['id_content']
    },
    siteLabelId () {
      return this.$store.getters['contentChanges/content_to_change']['siteLabel']['id_content']
    },
    fileLabelId () {
      return this.$store.getters['contentChanges/content_to_change']['fileLabel']['id_content']
    },
    emailLabelId () {
      return this.$store.getters['contentChanges/content_to_change']['emailLabel']['id_content']
    },
    textLabelId () {
      return this.$store.getters['contentChanges/content_to_change']['textLabel']['id_content']
    },
    regionLabelId () {
      return this.$store.getters['contentChanges/content_to_change']['regionLabel']['id_content']
    },
    contact_nameLabelId () {
      return this.$store.getters['contentChanges/content_to_change']['contact_nameLabel']['id_content']
    },
    nameSvgId () {
      return this.$store.getters['contentChanges/content_to_change']['nameSvg']['id_content']
    },
    companySvgId () {
      return this.$store.getters['contentChanges/content_to_change']['companySvg']['id_content']
    },
    siteSvgId () {
      return this.$store.getters['contentChanges/content_to_change']['siteSvg']['id_content']
    },
    fileSvgId () {
      return this.$store.getters['contentChanges/content_to_change']['fileSvg']['id_content']
    },
    emailSvgId () {
      return this.$store.getters['contentChanges/content_to_change']['emailSvg']['id_content']
    },
    textSvgId () {
      return this.$store.getters['contentChanges/content_to_change']['textSvg']['id_content']
    },
    regionSvgId () {
      return this.$store.getters['contentChanges/content_to_change']['regionSvg']['id_content']
    },
    contact_nameSvgId () {
      return this.$store.getters['contentChanges/content_to_change']['contact_nameSvg']['id_content']
    },
    nameLabelComputed() {
      if (this.$i18n.locale == 'ru') { return this.nameLabelRu }
      return this.nameLabelEng
    },
    companyLabelComputed() {
      if (this.$i18n.locale == 'ru') { return this.companyLabelRu }
      return this.companyLabelEng
    },
    siteLabelComputed() {
      if (this.$i18n.locale == 'ru') { return this.siteLabelRu }
      return this.siteLabelEng
    },
    fileLabelComputed() {
      if (this.$i18n.locale == 'ru') { return this.fileLabelRu }
      return this.fileLabelEng
    },
    emailLabelComputed() {
      if (this.$i18n.locale == 'ru') { return this.emailLabelRu }
      return this.emailLabelEng
    },
    textLabelComputed() {
      if (this.$i18n.locale == 'ru') { return this.textLabelRu }
      return this.textLabelEng
    },
    regionLabelComputed() {
      if (this.$i18n.locale == 'ru') { return this.regionLabelRu }
      return this.regionLabelEng
    },
    contact_nameLabelComputed() {
      if (this.$i18n.locale == 'ru') { return this.contact_nameLabelRu }
      return this.contact_nameLabelEng
    }
  },
  methods: {
    saveChanges () {
      let valueList = []
      if (this.nameLabelId) {
        valueList.push({
          'value_rus': this.nameLabelRu === undefined ? '' : this.nameLabelRu,
          'value_eng': this.nameLabelEng === undefined ? '' : this.nameLabelEng,
          'id_content': this.nameLabelId
        })
      }
      if (this.companyLabelId) {
        valueList.push({
          'value_rus': this.companyLabelRu === undefined ? '' : this.companyLabelRu,
          'value_eng': this.companyLabelEng === undefined ? '' : this.companyLabelEng,
          'id_content': this.companyLabelId
        })
      }
      if (this.siteLabelId) {
        valueList.push({
          'value_rus': this.siteLabelRu === undefined ? '' : this.siteLabelRu,
          'value_eng': this.siteLabelEng === undefined ? '' : this.siteLabelEng,
          'id_content': this.siteLabelId
        })
      }
      if (this.fileLabelId) {
        valueList.push({
          'value_rus': this.fileLabelRu === undefined ? '' : this.fileLabelRu,
          'value_eng': this.fileLabelEng === undefined ? '' : this.fileLabelEng,
          'id_content': this.fileLabelId
        })
      }
      if (this.emailLabelId) {
        valueList.push({
          'value_rus': this.emailLabelRu === undefined ? '' : this.emailLabelRu,
          'value_eng': this.emailLabelEng === undefined ? '' : this.emailLabelEng,
          'id_content': this.emailLabelId
        })
      }
      if (this.textLabelId) {
        valueList.push({
          'value_rus': this.textLabelRu === undefined ? '' : this.textLabelRu,
          'value_eng': this.textLabelEng === undefined ? '' : this.textLabelEng,
          'id_content': this.textLabelId
        })
      }
      if (this.regionLabelId) {
        valueList.push({
          'value_rus': this.regionLabelRu === undefined ? '' : this.regionLabelRu,
          'value_eng': this.regionLabelEng === undefined ? '' : this.regionLabelEng,
          'id_content': this.regionLabelId
        })
      }
      if (this.contact_nameLabelId) {
        valueList.push({
          'value_rus': this.contact_nameLabelRu === undefined ? '' : this.contact_nameLabelRu,
          'value_eng': this.contact_nameLabelEng === undefined ? '' : this.contact_nameLabelEng,
          'id_content': this.contact_nameLabelId
        })
      }
      if (this.nameSvgId) {
        valueList.push({
          'value_rus': this.nameSvg === undefined ? '' : this.nameSvg,
          'value_eng': this.nameSvg === undefined ? '' : this.nameSvg,
          'id_content': this.nameSvgId
        })
      }
      if (this.companySvgId) {
        valueList.push({
          'value_rus': this.companySvg === undefined ? '' : this.companySvg,
          'value_eng': this.companySvg === undefined ? '' : this.companySvg,
          'id_content': this.companySvgId
        })
      }
      if (this.siteSvgId) {
        valueList.push({
          'value_rus': this.siteSvg === undefined ? '' : this.siteSvg,
          'value_eng': this.siteSvg === undefined ? '' : this.siteSvg,
          'id_content': this.siteSvgId
        })
      }
      if (this.fileSvgId) {
        valueList.push({
          'value_rus': this.fileSvg === undefined ? '' : this.fileSvg,
          'value_eng': this.fileSvg === undefined ? '' : this.fileSvg,
          'id_content': this.fileSvgId
        })
      }
      if (this.emailSvgId) {
        valueList.push({
          'value_rus': this.emailSvg === undefined ? '' : this.emailSvg,
          'value_eng': this.emailSvg === undefined ? '' : this.emailSvg,
          'id_content': this.emailSvgId
        })
      }
      if (this.textSvgId) {
        valueList.push({
          'value_rus': this.textSvg === undefined ? '' : this.textSvg,
          'value_eng': this.textSvg === undefined ? '' : this.textSvg,
          'id_content': this.textSvgId
        })
      }
      if (this.regionSvgId) {
        valueList.push({
          'value_rus': this.regionSvg === undefined ? '' : this.regionSvg,
          'value_eng': this.regionSvg === undefined ? '' : this.regionSvg,
          'id_content': this.regionSvgId
        })
      }
      if (this.contact_nameSvgId) {
        valueList.push({
          'value_rus': this.contact_nameSvg === undefined ? '' : this.contact_nameSvg,
          'value_eng': this.contact_nameSvg === undefined ? '' : this.contact_nameSvg,
          'id_content': this.contact_nameSvgId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES', {
        'valueList': valueList
      })
    }
  }
}
</script>