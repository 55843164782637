<template>
  <div>
    <v-file-input
            v-model="file"
            @change="updateValue"
            label="Файл"
            show-size
            outlined
    />
  </div>
</template>

<script>
export default {
  name: "ChangeFile",
  props:['value'],
  data() {
    return {
      file: this.value
    }
  },
  methods: {
    updateValue() {
      this.$emit('input', this.file)
    }
  }
}
</script>
<style scoped>

</style>