<template>
  <div>
    <feedback-form
            :name-label="nameLabelComputed"
            :email-label="emailLabelComputed"
            :text-label="textLabelComputed"
            :name-svg="nameSvg"
            :email-svg="emailSvg"
            :text-svg="textSvg"
            :change="false"
        />
    <div v-if="lang=='ru'">
       <v-text-field
         v-if="nameLabelId"
         v-model="nameLabelRu"
         label="Имя русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
       <v-text-field
         v-if="nameLabelId"
         v-model="nameLabelEng"
         label="Имя английский"
         required
         outlined
       />
    </div>
    <div v-if="nameSvgId">
      <change-s-v-g
          v-model="nameSvg">
      </change-s-v-g>
    </div>
    <div v-if="lang=='ru'">
       <v-text-field
         v-if="emailLabelId"
         v-model="emailLabelRu"
         label="email русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
       <v-text-field
         v-if="emailLabelId"
         v-model="emailLabelEng"
         label="email английский"
         required
         outlined
       />
    </div>
    <div v-if="emailSvgId">
      <change-s-v-g
          v-model="emailSvg">
      </change-s-v-g>
    </div>
    <div v-if="lang=='ru'">
       <v-text-field
         v-if="textLabelId"
         v-model="textLabelRu"
         label="Текст русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
       <v-text-field
         v-if="textLabelId"
         v-model="textLabelEng"
         label="Текст английский"
         required
         outlined
       />
    </div>
    <div v-if="textSvgId">
      <change-s-v-g
          v-model="textSvg">
      </change-s-v-g>
    </div>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>

<script>
import FeedbackForm from "@/components/ui/FeedbackForm.vue";
import ChangeSVG from "@/components/ui/ChangeContent/ChangeSVG.vue";

export default {
  name: "ChangeFeedbackForm",
  components: {ChangeSVG, FeedbackForm},
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'nameLabelRu': content_to_change['nameLabel']['value_rus'],
      'emailLabelRu': content_to_change['emailLabel']['value_rus'],
      'textLabelRu': content_to_change['textLabel']['value_rus'],

      'nameLabelEng': content_to_change['nameLabel']['value_eng'],
      'emailLabelEng': content_to_change['emailLabel']['value_eng'],
      'textLabelEng': content_to_change['textLabel']['value_eng'],

      'nameSvg': content_to_change['nameSvg']['value_eng'],
      'emailSvg': content_to_change['emailSvg']['value_eng'],
      'textSvg': content_to_change['textSvg']['value_eng']
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    nameLabelId () {
      return this.$store.getters['contentChanges/content_to_change']['nameLabel']['id_content']
    },
    emailLabelId () {
      return this.$store.getters['contentChanges/content_to_change']['emailLabel']['id_content']
    },
    textLabelId () {
      return this.$store.getters['contentChanges/content_to_change']['textLabel']['id_content']
    },
    nameSvgId () {
      return this.$store.getters['contentChanges/content_to_change']['nameSvg']['id_content']
    },
    emailSvgId () {
      return this.$store.getters['contentChanges/content_to_change']['emailSvg']['id_content']
    },
    textSvgId () {
      return this.$store.getters['contentChanges/content_to_change']['textSvg']['id_content']
    },
    nameLabelComputed() {
      if (this.$i18n.locale == 'ru') { return this.nameLabelRu }
      return this.nameLabelEng
    },
    emailLabelComputed() {
      if (this.$i18n.locale == 'ru') { return this.emailLabelRu }
      return this.emailLabelEng
    },
    textLabelComputed() {
      if (this.$i18n.locale == 'ru') { return this.textLabelRu }
      return this.textLabelEng
    },
  },
  methods: {
    saveChanges () {
      let valueList = []
      if (this.nameLabelId) {
        valueList.push({
          'value_rus': this.nameLabelRu === undefined ? '' : this.nameLabelRu,
          'value_eng': this.nameLabelEng === undefined ? '' : this.nameLabelEng,
          'id_content': this.nameLabelId
        })
      }
      if (this.emailLabelId) {
        valueList.push({
          'value_rus': this.emailLabelRu === undefined ? '' : this.emailLabelRu,
          'value_eng': this.emailLabelEng === undefined ? '' : this.emailLabelEng,
          'id_content': this.emailLabelId
        })
      }
      if (this.textLabelId) {
        valueList.push({
          'value_rus': this.textLabelRu === undefined ? '' : this.textLabelRu,
          'value_eng': this.textLabelEng === undefined ? '' : this.textLabelEng,
          'id_content': this.textLabelId
        })
      }
      if (this.nameSvgId) {
        valueList.push({
          'value_rus': this.nameSvg === undefined ? '' : this.nameSvg,
          'value_eng': this.nameSvg === undefined ? '' : this.nameSvg,
          'id_content': this.nameSvgId
        })
      }
      if (this.emailSvgId) {
        valueList.push({
          'value_rus': this.emailSvg === undefined ? '' : this.emailSvg,
          'value_eng': this.emailSvg === undefined ? '' : this.emailSvg,
          'id_content': this.emailSvgId
        })
      }
      if (this.textSvgId) {
        valueList.push({
          'value_rus': this.textSvg === undefined ? '' : this.textSvg,
          'value_eng': this.textSvg === undefined ? '' : this.textSvg,
          'id_content': this.textSvgId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES', {
        'valueList': valueList
      })
    }
  }
}
</script>