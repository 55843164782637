<template>
 <div>
   <content-text
     :title="titleComputed"
     :sub-title="subTitleComputed"
     :content="contentComputed"
     :change="false"
     title-class="contentHeading"
     sub-title-class="contentSubTitle"
     text-class="contentText"
   />
   <div v-if="lang=='ru'">
     <v-text-field
       v-if="titleId"
       v-model="titleRu"
       label="Заголовок русский"
       required
       outlined
     />
     <v-text-field
       v-if="subTitleId"
       v-model="subTitleRu"
       label="Подзаголовок русский"
       required
       outlined
     />
     <ChangeHtml
         v-if="contentId"
         v-model="contentRu"
     />
     <v-textarea
         class="mt-4"
         v-if="contentId"
         v-model="contentRu"
         label="html разметка русский"
         required
         outlined
     />
   </div>
   <div v-if="lang=='en-US'">
     <v-text-field
       v-if="titleId"
       v-model="titleEng"
       label="Заголовок английский"
       required
       outlined
     />
     <v-text-field
       v-if="subTitleId"
       v-model="subTitleEng"
       label="Подзаголовок английский"
       required
       outlined
     />
     <ChangeHtml
         v-if="contentId"
         v-model="contentEng"
     />
     <v-textarea
         class="mt-4"
         v-if="contentId"
         v-model="contentEng"
         label="Основной текст английский"
         required
         outlined
     />
   </div>
   <v-btn @click="saveChanges">Сохранить изменения</v-btn>
 </div>
</template>

<script>
import ContentText from "@/components/ui/ContentText.vue";
import ChangeHtml from "@/components/ui/ChangeContent/ChangeHtml/ChangeHtml.vue";

export default  {
  name: 'ChangeContentText',
  components: {ChangeHtml, ContentText },
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'titleRu': content_to_change['title']['value_rus'],
      'subTitleRu': content_to_change['subTitle']['value_rus'],
      'contentRu': content_to_change['content']['value_rus'],

      'titleEng': content_to_change['title']['value_eng'],
      'subTitleEng': content_to_change['subTitle']['value_eng'],
      'contentEng': content_to_change['content']['value_eng'],
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    titleId () {
      return this.$store.getters['contentChanges/content_to_change']['title']['id_content']
    },
    subTitleId () {
      return this.$store.getters['contentChanges/content_to_change']['subTitle']['id_content']
    },
    contentId () {
      return this.$store.getters['contentChanges/content_to_change']['content']['id_content']
    },
    titleComputed() {
      if (this.$i18n.locale == 'ru') { return this.titleRu }
      return this.titleEng
    },
    subTitleComputed() {
      if (this.$i18n.locale == 'ru') { return this.subTitleRu }
      return this.subTitleEng
    },
    contentComputed() {
      if (this.$i18n.locale == 'ru') { return this.contentRu }
      return this.contentEng
    }
  },
  methods: {
    saveChanges () {
      let valueList = []
      if (this.titleId) {
        valueList.push({
          'value_rus': this.titleRu === undefined ? '' : this.titleRu,
          'value_eng': this.titleEng === undefined ? '' : this.titleEng,
          'id_content': this.titleId
        })
      }
      if (this.subTitleId) {
        valueList.push({
          'value_rus': this.subTitleRu === undefined ? '' : this.subTitleRu,
          'value_eng': this.subTitleEng === undefined ? '' : this.subTitleEng,
          'id_content': this.subTitleId
        })
      }
      if (this.contentId) {
        valueList.push({
          'value_rus': this.contentRu === undefined ? '' : this.contentRu,
          'value_eng': this.contentEng === undefined ? '' : this.contentEng,
          'id_content': this.contentId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES', {
        'valueList': valueList
      })
    }
  }
}
</script>