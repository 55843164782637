<template>
  <div>
    <tooltip-content
      :text="textComputed"
      :tolltipText="tolltipTextComputed"
      :svg-path="svgPath"
      class = ""
      :change="false"
    />
    <div v-if="lang=='ru'">
      <v-text-field
         v-if="textId"
         v-model="textRu"
         label="Текст русский"
         required
         outlined
       />
      <v-text-field
         v-if="tolltipTextId"
         v-model="tolltipTextRu"
         label="Текст подсказки русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
      <v-text-field
         v-if="textId"
         v-model="textEng"
         label="Текст английский"
         required
         outlined
       />
      <v-text-field
         v-if="tolltipTextId"
         v-model="tolltipTextEng"
         label="Текст подсказки английский"
         required
         outlined
       />
    </div>
    <div v-if="svgPathId">
        <change-s-v-g
            v-model="svgPath">
        </change-s-v-g>
    </div>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>
<script>
import TooltipContent from '@/components/ui/TooltipContent.vue'
import ChangeSVG from '@/components/ui/ChangeContent/ChangeSVG.vue'

export default {
  name: 'ChangeContentText',
  components: { TooltipContent, ChangeSVG },
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'textRu': content_to_change['text']['value_rus'],
      'tolltipTextRu': content_to_change['tolltipText']['value_rus'],
      'TextEng': content_to_change['text']['value_eng'],
      'tolltipTextEng': content_to_change['tolltipText']['value_eng'],
      'svgPath': content_to_change['svgPath']['value_eng']
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    textId () {
      return this.$store.getters['contentChanges/content_to_change']['text']['id_content']
    },
    tolltipTextId () {
      return this.$store.getters['contentChanges/content_to_change']['tolltipText']['id_content']
    },
    svgPathId () {
      return this.$store.getters['contentChanges/content_to_change']['svgPath']['id_content']
    },
    textComputed () {
      if (this.$i18n.locale == 'ru') { return this.textRu }
      return this.textEng
    },
    tolltipTextComputed () {
      if (this.$i18n.locale == 'ru') { return this.tolltipTextRu }
      return this.tolltipTextEng
    }
  },
  methods: {
    saveChanges () {
      let valueList = []
      if (this.textId) {
        valueList.push({
          'value_rus': this.textRu === undefined ? '' : this.textRu,
          'value_eng': this.textEng === undefined ? '' : this.textEng,
          'id_content': this.textId
        })
      }
      if (this.tolltipTextId) {
        valueList.push({
          'value_rus': this.tolltipTextRu === undefined ? '' : this.tolltipTextRu,
          'value_eng': this.tolltipTextEng === undefined ? '' : this.tolltipTextEng,
          'id_content': this.tolltipTextId
        })
      }
      valueList.push({
        'value_rus': this.svgPath === undefined ? '' : this.svgPath,
        'value_eng': this.svgPath === undefined ? '' : this.svgPath,
        'id_content': this.svgPathId
      })
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES', {
        'valueList': valueList
      })
    }
  }
}
</script>

<style scoped>

</style>
