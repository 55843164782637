<template>
  <div>
    <push-link
      :link-text="linkTextComputed"
      :push-link="pushLinkComputed"
      :change="false"
    />
    <div v-if="lang=='ru'">
      <v-text-field v-if="linkTextId"
                v-model="linkTextRu"
                label="Заголовок русский"
                required
                outlined
      />
    </div>
    <div v-if="lang=='en-US'">
      <v-text-field v-if="linkTextId"
                v-model="linkTextEng"
                label="Заголовок английский"
                required
                outlined
      />
    </div>
    <v-select
        v-if="pushLinkId"
        :items="this.$router.options.routes.filter(route=>!route.path.includes(':')).map(route=>route.path)"
        v-model="pushLink"
        label="ссылка кнопки"
        >
    </v-select>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>

<script>
import PushLink from "@/components/ui/PushLink.vue";

export default {
  name: 'changePushLink',
  components: { PushLink },
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'linkTextRu': content_to_change['linkText']['value_rus'],
      'pushLink': content_to_change['pushLink']['value_rus'],
      'linkTextEng': content_to_change['linkText']['value_eng']
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    linkTextId () {
      return this.$store.getters['contentChanges/content_to_change']['linkText']['id_content']
    },
    pushLinkId () {
      return this.$store.getters['contentChanges/content_to_change']['pushLink']['id_content']
    },
    linkTextComputed() {
      if (this.$i18n.locale == 'ru') { return this.linkTextRu }
      return this.linkTextEng
    },
    pushLinkComputed() {
      if (this.$i18n.locale == 'ru') { return this.pushLinkRu }
      return this.pushLinkEng
    }
  },
  methods: {
    saveChanges () {
      let valueList = []
      if (this.linkTextId) {
        valueList.push({
          'value_rus': this.linkTextRu === undefined ? '' : this.linkTextRu,
          'value_eng': this.linkTextEng === undefined ? '' : this.linkTextEng,
          'id_content': this.linkTextId
        })
      }
      if (this.pushLinkId) {
        valueList.push({
          'value_rus': this.pushLink === undefined ? '' : this.pushLink,
          'value_eng': this.pushLink === undefined ? '' : this.pushLink,
          'id_content': this.pushLinkId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES', {
        'valueList': valueList
      })
    }
  }
}
</script>

<style scoped>

</style>