<template>
<div>
    <div>
      <content-documents
              :href="href"
              :content="contentComputed"
              :change="false"
      />
    </div>
    <div v-if="lang=='ru'" >
        <v-text-field
                v-if="contentId"
                v-model="contentRu"
                label="Текст русский"
                required
                outlined
        />
    </div>
    <div v-if="lang=='en-US'">
        <v-text-field
                v-if="contentId"
                v-model="contentEng"
                label="Текст английский"
                required
                outlined
        />
    </div>
    <change-file
            v-if="hrefId"
            v-model="href"/>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>
<script>

import ContentDocuments from '@/components/ui/ContentDocuments'
import ChangeFile from "@/components/ui/ChangeContent/ChangeFile.vue";
export default {
  name: 'ChangeContentDocument',
  components: {ChangeFile, ContentDocuments },
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'href': content_to_change['href']['file'],
      'contentRu': content_to_change['content']['value_rus'],
      'contentEng': content_to_change['content']['value_eng']
    }
  },
  computed: {
    contentComputed () {
      if (this.$i18n.locale == 'ru') { return this.contentRu }
      return this.contentEng
    },
    hrefId(){
      return this.$store.getters['contentChanges/content_to_change']['href']['id_content']
    },
    contentId(){
      return this.$store.getters['contentChanges/content_to_change']['content']['id_content']
    },
    lang () {
      return this.$i18n.locale
    }
  },
  methods: {
    saveChanges () {
      let valueList=[]
      if (this.contentId) {
        valueList.push({
          'value_rus': this.contentRu === undefined ? '' : this.contentRu,
          'value_eng': this.contentEng === undefined ? '' : this.contentEng,
          'id_content': this.contentId
        })
      }
      if (this.hrefId) {
        valueList.push({
          'value_rus': '',
          'value_eng': '',
          'file': this.href,
          'id_content': this.hrefId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES',{
        'valueList': valueList
      })
    }
  }
}
</script>
<style scoped>

</style>
