<template>
<div>
    <div>
      <block-documents
              :title="titleComputed"
              :change="false"
      />
    </div>
    <div v-if="lang=='ru'" >
        <v-text-field
                v-if="titleId"
                v-model="titleRu"
                label="Текст русский"
                required
                outlined
        />
    </div>
    <div v-if="lang=='en-US'">
        <v-text-field
                v-if="titleId"
                v-model="titleEng"
                label="Текст английский"
                required
                outlined
        />
    </div>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>

<script>

import BlockDocuments from '@/components/ui/BlockDocuments'

export default {
  name: 'ChangeBlockDocuments',
  components: { BlockDocuments },
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'titleRu': content_to_change['title']['value_rus'],
      'titleEng': content_to_change['title']['value_eng']
    }
  },
  computed: {
    titleComputed () {
      if (this.$i18n.locale == 'ru') { return this.titleRu }
      return this.titleEng
    },
    lang () {
      return this.$i18n.locale
    },
    titleId () {
      return this.$store.getters['contentChanges/content_to_change']['title']['id_content']
    }
  },
  methods: {
    saveChanges () {
      let valueList = []
      if (this.titleId) {
        valueList.push({
          'value_rus': this.titleRu === undefined ? '' : this.titleRu,
          'value_eng': this.titleEng === undefined ? '' : this.titleEng,
          'id_content': this.titleId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES', {
        'valueList': valueList
      })
    }
  }
}
</script>
<style scoped>

</style>
