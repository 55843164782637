<template>
  <div class="p-5">
    <div
        class="editor"
        v-if="editor"
    >
    <menu-bar
      class="editor__header"
      :editor="editor"
    />
    <editor-content
      class="editor__content text-left p-1 border-0"
      :editor="editor"
    />
    </div>
    <div v-if="links.length>0">
      <h1>Ссылки</h1>
      <div>
        <p v-for="(link, index) in links" :key="index">
            {{link['text']}} -> {{link['href']}}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Document from '@tiptap/extension-document'
import History from '@tiptap/extension-history'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import MenuBar from '@/components/ui/ChangeContent/ChangeHtml/MenuBar.vue'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import { Editor, EditorContent } from '@tiptap/vue-2'
import { Span } from '@/components/ui/ChangeContent/ChangeHtml/CustomNodes/Span.js'

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  components: {
    MenuBar,
    EditorContent
  },
  data () {
    return {
      links: [],
      provider: null,
      editor: null,
      status: 'connecting',
      content: 'HI'
    }
  },
  watch: {
    value (value) {
      this.updateLinks()
      const isSame = this.editor.getHTML() === value
      if (isSame) {
        return
      }
      this.editor.commands.setContent(value, false)
    }
  },
  mounted () {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit.configure({
          history: false
        }),
        Highlight,
        TaskItem,
        TaskList,
        Text,
        Underline,
        Paragraph,
        History,
        Document,
        Span,
        Link.configure({
          HTMLAttributes: {
            class: 'colloredLink'
          }
        })
      ],
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML())
      },
      console: 'HI'
    })
    this.updateLinks()
  },
  methods: {
    updateLinks() {
      let parser = new DOMParser()
      let doc = parser.parseFromString(this.editor.getHTML(), 'text/html')
      let links = doc.querySelectorAll("a")
      let linksList = []
      links.forEach(link => linksList.push({ text: link.textContent, href: link.getAttribute('href') }))
      this.links = linksList
    }
  }
}
</script>
<style lang="scss">
.editor{
  background-color: #fff!important;
  border: 3px solid #0d0d0d!important;
  border-radius: 0.75rem!important;
  //color: #0d0d0d!important;
  display: flex!important;
  flex-direction: column!important;
  max-height: 26rem!important;

  &__header {
    align-items: center!important;
    border-bottom: 3px solid #0d0d0d!important;
    border-top-left-radius: 0.25rem!important;
    border-top-right-radius: 0.25rem!important;
    display: flex!important;
    flex: 0 0 auto!important;
    flex-wrap: wrap!important;
    padding: 0.25rem!important;
  }

  &__content {
    flex: 1.1 auto!important;
    overflow-x: hidden!important;
    overflow-y: auto!important;
    padding: 1.25rem 1rem!important;
    -webkit-overflow-scrolling: touch!important;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)!important;
      background-color: #f5f5f5!important;
    }
  }

  &__footer {
    align-items: center!important;
    border-top: 3px solid #0d0d0d!important;
    color: #0d0d0d!important;
    display: flex!important;
    flex: 0 0 auto!important;
    flex-wrap: wrap!important;
    font-size: 12px!important;
    font-weight: 600!important;
    justify-content: space-between!important;
    padding: 0.25rem 0.75rem!important;
    white-space: nowrap!important;
  }

  &__status {
    align-items: center!important;
    border-radius: 5px!important;
    display: flex!important;

    &::before {
      background: rgba(#0d0d0d,0.5)!important;
      border-radius: 50%!important;
      content: " "!important;
      display: inline-block!important;
      flex: 0 0 auto!important;
      height: 0.5rem!important;
      margin-right: 0.5rem!important;
      width: 0.5rem!important;
    }

    &--connecting::before{
      background: #616161!important;
    }

    &--connected::before{
      background: #b9f18d!important;
    }
  }

  &__name {
    button {
      background: none!important;
      border: none!important;
      border-radius: 0.4rem!important;
      color: #0d0d0d!important;
      font: inherit!important;
      font-size: 12px!important;
      font-weight: 600!important;
      padding: 0.25rem 0.5rem!important;

      &:hover {
        background-color: #0d0d0d!important;
        color: #fff!important;
      }
    }
  }

}

.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d!important;
  border-right: 1px solid #0d0d0d!important;
  margin-left: -1px!important;
  margin-right: -1px!important;
  pointer-events: none!important;
  position: relative!important;
  word-break: normal!important;
}

.collaboration-cursor__label {
  border-radius: 3px 3px 3px 0!important;
  color: #0d0d0d!important;
  font-size: 12px!important;
  font-style: normal!important;
  font-weight: 600!important;
  left: -1px!important;
  line-height: normal!important;
  padding: 0.1rem 0.3rem!important;
  position: absolute!important;
  top: -1.4em!important;
  user-select: none!important;
  white-space: nowrap!important;
}

.ProseMirror {
  &:focus {
    border:0 !important;
    outline: none !important;
  }
  > * + * {
    margin-top: 0.75em;
  }
  ul,ol {
    padding: 0 1rem !important;
  }
  ul {
    display: block!important;
    list-style-type: disc!important;
    margin-block-start: 1em!important;
    margin-block-end: 1em!important;
    margin-inline-start: 0px!important;
    margin-inline-end: 0px!important;
    padding-inline-start: 40px!important;
  }
  ol {
    display: block!important;
    list-style-type: decimal!important;
    margin-block-start: 1em!important;
    margin-block-end: 1em!important;
    margin-inline-start: 0px!important;
    margin-inline-end: 0px!important;
    padding-inline-start: 40px!important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    line-height: 1.1!important;
  }
  p{
    display: block!important;
    margin-block-start: 1em!important;
    margin-block-end: 1em!important;
    margin-inline-start: 0px!important;
    margin-inline-end: 0px!important;
    font-size: 16px!important;
  }
  span {
    color: #2a7070;
  }
  h1{
    display: block!important;
    font-size: 2em!important;
    margin-block-start: 0.67em!important;
    margin-block-end: 0.67em!important;
    margin-inline-start: 0px!important;
    margin-inline-end: 0px!important;
    font-weight: bold!important;
  }
  h2{
    display: block!important;
    font-size: 1.5em!important;
    margin-block-start: 0.83em!important;
    margin-block-end: 0.83em!important;
    margin-inline-start: 0px!important;
    margin-inline-end: 0px!important;
    font-weight: bold!important;
  }
  code{
    background-color: red!important;
    color: #616161!important;
  }
  pre{
    background: #0d0d0d!important;
    border-radius: 0.5rem!important;
    color: #fff!important;
    font-family: "JetBrains Mono", monospace!important;
    padding: 0.75rem 1rem!important;

    code{
    background: none!important;
    color: inherit!important;
    font-size: 0.8rem!important;
    padding: 0!important;
    }
  }
  mark{
    background-color: #faf594!important;
  }

  img {
    height: auto!important;
    max-width: 100%!important;
  }

  hr {
    margin: 1rem 0!important;
  }

  blockquote {
    border-left: 2px solid rgba(#0d0d0d, 0.1)!important;
    padding-left: 1rem!important;
  }

  hr {
    border: none!important;
    border-top: 2px solid rgba(#0d0d0d, 0.1)!important;
    margin: 2rem 0!important;
  }

  ul[data-type="taskList"] {
    list-style: none!important;
    padding: 0!important;

    li{
      align-items: center!important;
      display: flex!important;

      > label {
        flex: 0 0 auto!important;
        margin-right: 0.5rem!important;
        user-select: none!important;
      }

      >div{
        flex: 1 1 auto!important;
      }
    }
  }

}

</style>
