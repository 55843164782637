<template>
  <div>
    <card-management
        :pathImg="this.pathImgComputed"
        :fio="this.fioComputed"
        :post="this.postComputed"
        :change="false"
    />
    <div v-if="lang=='ru'">
        <v-text-field v-if="fioId"
                v-model="fioRu"
                label="Текст русский"
                required
                outlined
        />
        <v-text-field v-if="postId"
                v-model="postRu"
                label="Число 1 русский"
                required
                outlined
        />
    </div>
    <div v-if="lang=='en-US'">
        <v-text-field
                v-if="fioId"
                v-model="fioEng"
                label="Текст русский"
                required
                outlined
        />
        <v-text-field
                v-if="postId"
                v-model="postEng"
                label="Число 1 русский"
                required
                outlined
        />
    </div>
    <change-file
            v-if="pathImgId"
            v-model="pathImg"/>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>

<script>
import CardManagement from "@/components/ui/CardManagement.vue"
import ChangeFile from '@/components/ui/ChangeContent/ChangeFile.vue'
export default {
  name: 'ChangeCardManagement',
  components: {ChangeFile, CardManagement },
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'pathImg': content_to_change['pathImg']['file'],

      'fioRu': content_to_change['fio']['value_rus'],
      'postRu': content_to_change['post']['value_rus'],

      'fioEng': content_to_change['post']['value_eng'],
      'postEng': content_to_change['post']['value_eng']
    }
  },
  computed: {
    fioComputed () {
      if (this.$i18n.locale == 'ru') { return this.fioRu }
      return this.fioEng
    },
    postComputed () {
      if (this.$i18n.locale == 'ru') { return this.postRu }
      return this.postEng
    },
    pathImgComputed () {
      return this.pathImg
    },
    lang() {
      return this.$i18n.locale
    },
    fioId(){
      return this.$store.getters['contentChanges/content_to_change']['fio']['id_content']
    },
    postId(){
      return this.$store.getters['contentChanges/content_to_change']['post']['id_content']
    },
    pathImgId(){
      return this.$store.getters['contentChanges/content_to_change']['pathImg']['id_content']
    }
  },
  methods: {
    saveChanges () {
      let valueList=[]
      if (this.fioId) {
        valueList.push({
          'value_rus': this.fioRu === undefined ? '' : this.fioRu,
          'value_eng': this.fioEng === undefined ? '' : this.fioEng,
          'id_content': this.fioId
        })
      }
      if (this.postId) {
        valueList.push({
          'value_rus': this.postRu === undefined ? '' : this.postRu,
          'value_eng': this.postEng === undefined ? '' : this.postEng,
          'id_content': this.postId
        })
      }
      if (this.pathImgId) {
        valueList.push({
          'value_rus': '',
          'value_eng': '',
          'file': this.pathImg,
          'id_content': this.pathImgId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES',{
        'valueList':valueList
      })
    }
  }
}
</script>

<style scoped>

</style>
