<template>
  <div>
    <content-frame
          :frame-src="frameSrc"
          :change="true"
    />
    <v-text-field v-if="frameSrcId"
              v-model="frameSrc"
              label="Текст русский"
              required
              outlined
    />
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>

<script>
import ContentFrame from "@/components/ui/ContentFrame.vue";
export default {
  name: "ChangeContentFrame",
  components: { ContentFrame },
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'frameSrc': content_to_change['frameSrc']['value_rus']
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },
    frameSrcId() {
      return this.$store.getters['contentChanges/content_to_change']['frameSrc']['id_content']
    }
  }
}
</script>

<style scoped>

</style>