<template>
  <div>
    <div>
      <cards-with-numbers
              :text="this.textComputed"
              :big-num="bigNumComputed"
              :small-num="this.smallNumComputed"
              :unit="this.unitComputed"
              :symbol="this.symbolComputed"
              :svg-path="this.svgPathComputed"
              :change="false"
      />
    </div>
    <div v-if="lang=='ru'" >
        <v-text-field
                v-if="textId"
                v-model="textRu"
                label="Текст русский"
                required
                outlined
        />
        <v-text-field
                v-if="bigNumId"
                v-model="bigNumRu"
                label="Число 1 русский"
                required
                outlined
        />
        <v-text-field
                v-if="smallNumId"
                v-model="smallNumRu"
                label="Число 2 русский"
                required
                outlined
        />
        <v-text-field
                v-if="unitId"
                v-model="unitRu"
                label="единицы русский"
                required
                outlined
        />
        <v-text-field
                v-if="symbolId"
                v-model="symbolRu"
                label="Символ русский"
                required
                outlined
        />
    </div>
    <div v-if="lang=='en-US'">
        <v-text-field
                v-if="textId"
                v-model="textEng"
                label="Текст английский"
                required
                outlined
        />
        <v-text-field
                v-if="bigNumId"
                v-model="bigNumEng"
                label="Число 1 английский?"
                required
                outlined
        />
        <v-text-field
                v-if="smallNumId"
                v-model="smallNumEng"
                label="Число 2 английский"
                required
                outlined
        />
        <v-text-field
                v-if="unitId"
                v-model="unitEng"
                label="единицы английский"
                required
                outlined
        />
        <v-text-field
                v-if="symbolId"
                v-model="symbolEng"
                label="Символ английский"
                required
                outlined
        />
    </div>
    <div v-if="svgPathId">
      <change-s-v-g
          v-model="svgPath">
      </change-s-v-g>
    </div>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>
<script>
import CardsWithNumbers from '@/components/ui/CardsWithNumbers.vue'
import ChangeSVG from '@/components/ui/ChangeContent/ChangeSVG.vue'
export default {
  name: 'ChangeCardsWithNumbers',
  components: { ChangeSVG, CardsWithNumbers },
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'textRu': content_to_change['text']['value_rus'],
      'bigNumRu': content_to_change['bigNum']['value_rus'],
      'smallNumRu': content_to_change['smallNum']['value_rus'],
      'unitRu': content_to_change['unit']['value_rus'],
      'symbolRu': content_to_change['symbol']['value_rus'],
      'svgPath': content_to_change['svgPath']['value_rus'],

      'textEng': content_to_change['text']['value_eng'],
      'bigNumEng': content_to_change['bigNum']['value_eng'],
      'smallNumEng': content_to_change['smallNum']['value_eng'],
      'unitEng': content_to_change['unit']['value_eng'],
      'symbolEng': content_to_change['symbol']['value_eng'],
      'svgPathEng': content_to_change['svgPath'['value_rus']]
    }
  },
  computed: {
    textComputed () {
      if (this.$i18n.locale == 'ru') { return this.textRu }
      return this.textEng
    },
    bigNumComputed () {
      if (this.$i18n.locale == 'ru') { return this.bigNumRu }
      return this.bigNumEng
    },
    smallNumComputed () {
      if (this.$i18n.locale == 'ru') { return this.smallNumRu }
      return this.smallNumEng
    },
    unitComputed () {
      if (this.$i18n.locale == 'ru') { return this.unitRu }
      return this.unitEng
    },
    symbolComputed () {
      if (this.$i18n.locale == 'ru') { return this.symbolRu }
      return this.symbolEng
    },
    svgPathComputed () {
      return this.svgPath
    },
    lang () {
      return this.$i18n.locale
    },
    textId () {
      return this.$store.getters['contentChanges/content_to_change']['text']['id_content']
    },
    bigNumId () {
      return this.$store.getters['contentChanges/content_to_change']['bigNum']['id_content']
    },
    smallNumId () {
      return this.$store.getters['contentChanges/content_to_change']['smallNum']['id_content']
    },
    unitId () {
      return this.$store.getters['contentChanges/content_to_change']['unit']['id_content']
    },
    symbolId () {
      return this.$store.getters['contentChanges/content_to_change']['symbol']['id_content']
    },
    svgPathId () {
      return this.$store.getters['contentChanges/content_to_change']['svgPath']['id_content']
    }
  },
  methods: {
    saveChanges () {
      let valueList = []
      if (this.textId) {
        valueList.push({
          'value_rus': this.textRu === undefined ? '' : this.textRu,
          'value_eng': this.textEng === undefined ? '' : this.textEng,
          'id_content': this.textId
        })
      }
      if (this.bigNumId) {
        valueList.push({
          'value_rus': this.bigNumRu === undefined ? '' : this.bigNumRu,
          'value_eng': this.bigNumEng === undefined ? '' : this.bigNumEng,
          'id_content': this.bigNumId
        })
      }
      if (this.smallNumId) {
        valueList.push({
          'value_rus': this.smallNumRu === undefined ? '' : this.smallNumRu,
          'value_eng': this.smallNumEng === undefined ? '' : this.smallNumEng,
          'id_content': this.smallNumId
        })
      }
      if (this.unitId) {
        valueList.push({
          'value_rus': this.unitRu === undefined ? '' : this.unitRu,
          'value_eng': this.unitEng === undefined ? '' : this.unitEng,
          'id_content': this.unitId
        })
      }
      if (this.symbolId){
        valueList.push({
          'value_rus': this.symbolRu === undefined ? '' : this.symbolRu,
          'value_eng': this.symbolEng === undefined ? '' : this.symbolEng,
          'id_content': this.symbolId
        })
      }
      if (this.svgPathId){
        valueList.push({
          'value_rus': this.svgPath === undefined ? '' : this.svgPath,
          'value_eng': this.svgPath === undefined ? '' : this.svgPath,
          'id_content': this.svgPathId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES', {
        'valueList': valueList
      })
    }
  }
}
</script>
