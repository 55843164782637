<template>
  <div>
    <div>
      <icon-text
          :text="this.textComputed"
          :svg-path="this.svgPathComputed"
          :change="false"
      />
    </div>
    <div v-if="lang=='ru'">
      <v-text-field
                  v-if="textId"
                  v-model="textRu"
                  label="Текст русский"
                  required
                  outlined
      />
    </div>
    <div v-if="lang=='en-US'">
      <v-text-field
                  v-if="textId"
                  v-model="textEng"
                  label="Текст русский"
                  required
                  outlined
      />
    </div>
    <div v-if="svgPathId">
      <change-s-v-g
          v-model="svgPath">
      </change-s-v-g>
    </div>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>
<script>
import IconText from '@/components/ui/IconText.vue'
import ChangeSVG from '@/components/ui/ChangeContent/ChangeSVG.vue'

export default {
  name: 'ChangeIconText',
  components: { ChangeSVG, IconText },
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'textRu': content_to_change['text']['value_rus'],
      'textEng': content_to_change['text']['value_eng'],
      'svgPath': content_to_change['svgPath']['value_rus']
    }
  },
  computed: {
    textComputed () {
      if (this.$i18n.locale == 'ru') { return this.textRu }
      return this.textEng
    },
    svgPathComputed () {
      return this.svgPath
    },
    lang () {
      return this.$i18n.locale
    },
    textId () {
      return this.$store.getters['contentChanges/content_to_change']['text']['id_content']
    },
    svgPathId () {
      return this.$store.getters['contentChanges/content_to_change']['svgPath']['id_content']
    }
  },
  methods: {
    saveChanges () {
      let valueList = []
      if (this.textId) {
        valueList.push({
          'value_rus': this.textRu === undefined ? '' : this.textRu,
          'value_eng': this.textEng === undefined ? '' : this.textEng,
          'id_content': this.textId
        })
      }
      if (this.svgPathId) {
        valueList.push({
          'value_rus': this.svgPath === undefined ? '' : this.svgPath,
          'value_eng': this.svgPath === undefined ? '' : this.svgPath,
          'id_content': this.svgPathId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES', {
        'valueList': valueList
      })
    }
  }
}
</script>

<style scoped>

</style>
