<template>
  <div>
      <swiper :options="swiperOption"
                class="no-indentation mainSwiper">
            <div class="parallax-bg"
                 slot="parallax-bg"
                 data-swiper-parallax="-23%"
                 ></div>
      <main-slider-slide
      :slide-header="slideHeaderComputed"
      :slide-text="slideTextComputed"
      :slide-btn-text ="slideBtnTextComputed"
      :change="false"/>
      </swiper>
      <div v-if="lang=='ru'">
        <v-text-field v-if="slideHeaderId"
                v-model="slideHeaderRu"
                label="Заголовок русский"
                required
                outlined
        />
        <v-text-field v-if="slideTextId"
                v-model="slideTextRu"
                label="Текст русский"
                required
                outlined
        />
        <v-text-field v-if="slideBtnTextId"
                v-model="slideBtnTextRu"
                label="Текст русский"
                required
                outlined
        />
    </div>
    <div v-if="lang=='en-US'">
        <v-text-field
                v-if="slideHeaderId"
                v-model="slideHeaderEng"
                label="Заголовок английский"
                required
                outlined
        />
        <v-text-field
                v-if="slideTextId"
                v-model="slideTextEng"
                label="Текст английский"
                required
                outlined
        />
        <v-text-field
                v-if="slideBtnTextId"
                v-model="slideBtnTextEng"
                label="Текст английский"
                required
                outlined
        />
    </div>
    <v-select
        v-if="slideBtnLinkId"
        :items="this.$router.options.routes.filter(route=>!route.path.includes(':')).map(route=>route.path)"
        v-model="slideBtnLink"
        label="ссылка кнопки"
        >
    </v-select>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import MainSliderSlide from '@/components/ui/MainSliderSlide.vue'
import ChangeFile from '@/components/ui/ChangeContent/ChangeFile.vue'
import CardManagement from '@/components/ui/CardManagement.vue'
import { swiper } from 'vue-awesome-swiper'
export default {
  name: 'ChangeMainSliderSlide',
  components: { ChangeFile, CardManagement, swiper },
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'slideHeaderRu': content_to_change['slideHeader']['value_rus'],
      'slideTextRu': content_to_change['slideText']['value_rus'],
      'slideBtnTextRu': content_to_change['slideBtnText']['value_rus'],
      'slideHeaderEng': content_to_change['slideHeader']['value_eng'],
      'slideTextEng': content_to_change['slideText']['value_eng'],
      'slideBtnTextEng': content_to_change['slideBtnText']['value_eng'],
      'slideBtnLink': content_to_change['slideBtnLink']['value_rus'],
      swiperOption: {
        speed: 1200,
        loop: true,
        autoplay: {
          delay: 7000,
          disableOnInteraction: false
        },
        parallax: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
    slideHeaderComputed () {
      if (this.$i18n.locale == 'ru') {
        return this.slideHeaderRu
      }
      return this.slideHeaderEng
    },
    slideTextComputed () {
      if (this.$i18n.locale == 'ru') {
        return this.slideTextRu
      }
      return this.slideTextEng
    },
    slideBtnTextComputed () {
      if (this.$i18n.locale == 'ru') {
        return this.slideBtnTextRu
      }
      return this.slideBtnTextEng
    },
    lang () {
      return this.$i18n.locale
    },
    slideHeaderId () {
      return this.$store.getters['contentChanges/content_to_change']['slideHeader']['id_content']
    },
    slideTextId () {
      return this.$store.getters['contentChanges/content_to_change']['slideText']['id_content']
    },
    slideBtnTextId () {
      return this.$store.getters['contentChanges/content_to_change']['slideBtnText']['id_content']
    },
    slideBtnLinkId () {
      return this.$store.getters['contentChanges/content_to_change']['slideBtnLink']['id_content']
    }
  },
  methods: {
    saveChanges () {
      let valueList = []
      if (this.slideHeaderId) {
        valueList.push({
          'value_rus': this.slideHeaderRu === undefined ? '' : this.slideHeaderRu,
          'value_eng': this.slideHeaderEng === undefined ? '' : this.slideHeaderEng,
          'id_content': this.slideHeaderId
        })
      }
      if (this.slideTextId) {
        valueList.push({
          'value_rus': this.slideTextRu === undefined ? '' : this.slideTextRu,
          'value_eng': this.slideTextEng === undefined ? '' : this.slideTextEng,
          'id_content': this.slideTextId
        })
      }
      if (this.slideBtnTextId) {
        valueList.push({
          'value_rus': this.slideBtnTextRu === undefined ? '' : this.slideBtnTextRu,
          'value_eng': this.slideBtnTextEng === undefined ? '' : this.slideBtnTextEng,
          'id_content': this.slideBtnTextId
        })
      }
      if (this.slideBtnLinkId) {
        valueList.push({
          'value_rus': this.slideBtnLink === undefined ? '' : this.slideBtnLink,
          'value_eng': this.slideBtnLink === undefined ? '' : this.slideBtnLink,
          'id_content': this.slideBtnLinkId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES', {
        'valueList': valueList
      })
    }
  }
}
</script>
