<template>
  <div>
    <p>{{this.message}}</p>
    <v-text-field
            v-model="login"
            label="Логин"
    />
    <v-text-field
            v-model="password"
            label="Пароль"
    />
    <v-btn v-if="isAuth" @click="logout_auth">Выйти из системы</v-btn>
    <v-btn v-else @click="login_auth">Авторизироваться</v-btn>
  </div>
</template>

<script>
export default {
  name: 'CheckLogin',
  data() {
    if (this.$store.getters['adminAuth/checkAuth']) {
      this.$store.dispatch('adminAuth/SET_MESSAGE',{
        'message':'Вы авторизированы'
      })
    }
    else {
      this.$store.dispatch('adminAuth/SET_MESSAGE',{
        'message':'Вы не авторизированы'
      })
    }
    return {
      login: '',
      password: ''
    }
  },
  computed: {
    message() {
      return this.$store.getters['adminAuth/message']
    },
    isAuth() {
      return this.$store.getters['adminAuth/checkAuth']
    }
  },
  methods: {
    login_auth: async function () {
      this.$store.dispatch('adminAuth/LOGIN',{
        'login': this.login,
        'password': this.password
      })
    },
    logout_auth: async function () {
      this.$store.dispatch('adminAuth/LOGOUT')
    }
  },
  metaInfo: {
    meta: [
      { name: 'description',
        content: 'Форма для включения изменение контента сайта' },
      { name: 'title',
        content: 'Форма для включения изменение контента сайта' }
    ]
  }
}
</script>
