<template>
  <div>
    <footer-img-links
      :path-img="this.pathImgComputed"
      :link="this.link"
      :change="false"
    />
    <v-text-field v-if="linkId"
                v-model="link"
                label="Ссылка"
                required
                outlined
    />
    <change-file
            v-if="pathImgId"
            v-model="pathImg"/>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>
<script>
import FooterImgLinks from "@/components/ui/FooterImgLinks.vue";
import ChangeFile from "@/components/ui/ChangeContent/ChangeFile.vue";
export default {
  name: "ChangeFooterImgLinks",
  components: {ChangeFile, FooterImgLinks},
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'pathImg': content_to_change['pathImg']['file'],
      'link': content_to_change['link']['value_rus']
    }
  },
  computed: {
    pathImgId() {
      return this.$store.getters['contentChanges/content_to_change']['pathImg']['id_content']
    },
    linkId() {
      return this.$store.getters['contentChanges/content_to_change']['link']['id_content']
    },
    pathImgComputed () {
      return this.pathImg
    }
  },
  methods: {
    saveChanges () {
      let valueList=[]
      if (this.pathImgId) {
        valueList.push({
          'value_rus': '',
          'value_eng': '',
          'file': this.pathImg,
          'id_content': this.pathImgId
        })
      }
      if (this.linkId) {
        valueList.push({
          'value_rus': this.link === undefined ? '' : this.link,
          'value_eng': this.link === undefined ? '' : this.link,
          'id_content': this.linkId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES',{
        'valueList': valueList
      })
    }
  }
}
</script>

<style scoped>

</style>