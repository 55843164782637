<template>
  <div>
    <footer-icon-text
      :text-before="this.textBeforeComputed"
      :text-after="this.textAfterComputed"
      :svg-path="this.svgPath"
      :change="false"
    />
    <div v-if="lang=='ru'">
      <v-text-field
                  v-if="textBeforeId"
                  v-model="textBeforeRu"
                  label="Текст до иконки русский"
                  required
                  outlined
      />
      <v-text-field
                  v-if="textAfterId"
                  v-model="textAfterRu"
                  label="Текст после русский"
                  required
                  outlined
      />
    </div>
    <div v-if="lang=='en-US'">
      <v-text-field
                  v-if="textBeforeId"
                  v-model="textBeforeEng"
                  label="Текст до иконки английский"
                  required
                  outlined
      />
      <v-text-field
                  v-if="textAfterId"
                  v-model="textAfterEng"
                  label="Текст после английский"
                  required
                  outlined
      />
    </div>
    <div v-if="svgPathId">
      <change-s-v-g
          v-model="svgPath">
      </change-s-v-g>
    </div>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>
<script>
import FooterIconText from "@/components/ui/FooterIconText.vue";
import ChangeSVG from "@/components/ui/ChangeContent/ChangeSVG.vue";

export default {
  name: "ChangeFooterIconText",
  components: {ChangeSVG, FooterIconText},
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'textBeforeRu': content_to_change['textBefore']['value_rus'],
      'textAfterRu': content_to_change['textAfter']['value_rus'],
      'textBeforeEng': content_to_change['textBefore']['value_eng'],
      'textAfterEng': content_to_change['textAfter']['value_eng'],
      'svgPath': content_to_change['svgPath']['value_rus']
    }
  },
  computed: {
    textBeforeComputed () {
      if (this.$i18n.locale == 'ru') { return this.textBeforeRu }
      return this.textBeforeEng
    },
    textAfterComputed () {
      if (this.$i18n.locale == 'ru') { return this.textAfterRu }
      return this.textAfterEng
    },
    svgPathComputed () {
      return this.svgPath
    },
    lang () {
      return this.$i18n.locale
    },
    textBeforeId () {
      return this.$store.getters['contentChanges/content_to_change']['textBefore']['id_content']
    },
    textAfterId () {
      return this.$store.getters['contentChanges/content_to_change']['textAfter']['id_content']
    },
    svgPathId () {
      return this.$store.getters['contentChanges/content_to_change']['svgPath']['id_content']
    }
  },
  methods: {
    saveChanges () {
      let valueList = []
      if (this.textBeforeId) {
        valueList.push({
          'value_rus': this.textBeforeRu === undefined ? '' : this.textBeforeRu,
          'value_eng': this.textBeforeEng === undefined ? '' : this.textBeforeEng,
          'id_content': this.textBeforeId
        })
      }
      if (this.svgPathId) {
        valueList.push({
          'value_rus': this.svgPath === undefined ? '' : this.svgPath,
          'value_eng': this.svgPath === undefined ? '' : this.svgPath,
          'id_content': this.svgPathId
        })
      }
      if (this.textAfterId) {
        valueList.push({
          'value_rus': this.textAfterRu === undefined ? '' : this.textAfterRu,
          'value_eng': this.textAfterEng === undefined ? '' : this.textAfterEng,
          'id_content': this.textAfterId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES', {
        'valueList': valueList
      })
    }
  }
}
</script>

<style scoped>

</style>