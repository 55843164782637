
import {
  Mark,
  markInputRule,
  markPasteRule,
  mergeAttributes,
} from '@tiptap/core'

// export interface SpanOptions {
//   HTMLAttributes: Record<string, any>,
// }
//
// declare module '@tiptap/core' {
//   interface Commands<ReturnType> {
//     span: {
//       /**
//        * Set a span mark
//        */
//       setSpan: () => ReturnType,
//       /**
//        * Toggle a span mark
//        */
//       toggleSpan: () => ReturnType,
//       /**
//        * Unset a span mark
//        */
//       unsetSpan: () => ReturnType,
//     }
//   }
// }

export const starInputRegex = /(?:^|\s)((?:\*\*)((?:[^*]+))(?:\*\*))$/
export const starPasteRegex = /(?:^|\s)((?:\*\*)((?:[^*]+))(?:\*\*))/g
export const underscoreInputRegex = /(?:^|\s)((?:__)((?:[^__]+))(?:__))$/
export const underscorePasteRegex = /(?:^|\s)((?:__)((?:[^__]+))(?:__))/g

export const Span = Mark.create({
  name: 'span',

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'contentNote'
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: node => {
          node.classList.contains('contentNote')
        }
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setSpan: () => ({ commands }) => {
        return commands.setMark(this.name)
      },
      toggleSpan: () => ({ commands }) => {
        return commands.toggleMark(this.name)
      },
      unsetSpan: () => ({ commands }) => {
        return commands.unsetMark(this.name)
      },
    }
  },

  addInputRules() {
    return [
      markInputRule({
        find: starInputRegex,
        type: this.type,
      }),
      markInputRule({
        find: underscoreInputRegex,
        type: this.type,
      }),
    ]
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: starPasteRegex,
        type: this.type,
      }),
      markPasteRule({
        find: underscorePasteRegex,
        type: this.type,
      }),
    ]
  },
})