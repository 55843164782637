<template>
  <div>
    <img-content
            :path-img="this.pathImgComputed"
            :change="false"
    />
    <change-file
            v-if="pathImgId"
            v-model="pathImg"/>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>

<script>
import ImgContent from "@/components/ui/ImgContent.vue";
import ChangeFile from '@/components/ui/ChangeContent/ChangeFile.vue'
export default {
  name: 'ChangeImgContent',
  components: {ImgContent, ChangeFile},
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'pathImg': content_to_change['pathImg']['file']
    }
  },
  computed: {
    pathImgId() {
      return this.$store.getters['contentChanges/content_to_change']['pathImg']['id_content']
    },
    pathImgComputed () {
      return this.pathImg
    }
  },
  methods: {
    saveChanges () {
      let valueList=[]
      if (this.pathImgId) {
        valueList.push({
          'value_rus': '',
          'value_eng': '',
          'file': this.pathImg,
          'id_content': this.pathImgId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES',{
        'valueList': valueList
      })
    }
  }
}
</script>