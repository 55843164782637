<template>
  <div>
    <s-v-g-link-text
        :text="this.textComputed"
        :link="this.link"
        :svg-path="this.svgPath"
        :change="false"
    />
    <div v-if="lang=='ru'" >
        <v-text-field
                v-if="textId"
                v-model="textRu"
                label="Текст русский"
                required
                outlined
        />
    </div>
    <div v-if="lang=='en-US'" >
        <v-text-field
                v-if="textId"
                v-model="textEng"
                label="Текст русский"
                required
                outlined
        />
    </div>
    <v-text-field
                v-if="linkId"
                v-model="link"
                label="Ссылка"
                required
                outlined
        />
    <div v-if="svgPathId">
      <change-s-v-g
          v-model="svgPath">
      </change-s-v-g>
    </div>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>

<script>
import SVGLinkText from "@/components/ui/SVGLinkText.vue";
import ChangeSVG from "@/components/ui/ChangeContent/ChangeSVG.vue";

export default {
  name: "ChangeSVGLinkText",
  components: {ChangeSVG, SVGLinkText},
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'textRu': content_to_change['text']['value_rus'],
      'link': content_to_change['link']['value_rus'],
      'textEng': content_to_change['text']['value_eng'],
      'svgPath': content_to_change['svgPath']['value_rus']
    }
  },
  computed: {
    textComputed () {
      if (this.$i18n.locale == 'ru') { return this.textRu }
      return this.textEng
    },
    lang () {
      return this.$i18n.locale
    },
    textId () {
      return this.$store.getters['contentChanges/content_to_change']['text']['id_content']
    },
    svgPathId () {
      return this.$store.getters['contentChanges/content_to_change']['svgPath']['id_content']
    },
    linkId () {
      return this.$store.getters['contentChanges/content_to_change']['link']['id_content']
    }
  },
  methods: {
    saveChanges () {
      let valueList = []
      if (this.textId) {
        valueList.push({
          'value_rus': this.textRu === undefined ? '' : this.textRu,
          'value_eng': this.textEng === undefined ? '' : this.textEng,
          'id_content': this.textId
        })
      }
      if (this.svgPathId){
        valueList.push({
          'value_rus': this.svgPath === undefined ? '' : this.svgPath,
          'value_eng': this.svgPath === undefined ? '' : this.svgPath,
          'id_content': this.svgPathId
        })
      }
      if (this.linkId){
        valueList.push({
          'value_rus': this.link === undefined ? '' : this.link,
          'value_eng': this.link === undefined ? '' : this.link,
          'id_content': this.linkId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES', {
        'valueList': valueList
      })
    }
  }
}
</script>

<style scoped>

</style>