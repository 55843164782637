<template>
  <div>
    <work-form
            :fio-label="fioLabelComputed"
            :vacantion-label="vacantionLabelComputed"
            :city-label="cityLabelComputed"
            :file-label="fileLabelComputed"
            :email-label="emailLabelComputed"
            :text-label="textLabelComputed"
            :fio-svg="fioSvg"
            :vacantion-svg="vacantionSvg"
            :city-svg="citySvg"
            :file-svg="fileSvg"
            :email-svg="emailSvg"
            :text-svg="textSvg"
            :change="false"
        />
    <div v-if="lang=='ru'">
       <v-text-field
         v-if="fioLabelId"
         v-model="fioLabelRu"
         label="Фамилия русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
       <v-text-field
         v-if="fioLabelId"
         v-model="fioLabelEng"
         label="Фамилия английский"
         required
         outlined
       />
    </div>
    <div v-if="fioSvgId">
      <change-s-v-g
          v-model="fioSvg">
      </change-s-v-g>
    </div>
    <div v-if="lang=='ru'">
       <v-text-field
         v-if="vacantionLabelId"
         v-model="vacantionLabelRu"
         label="Должность русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
       <v-text-field
         v-if="vacantionLabelId"
         v-model="vacantionLabelEng"
         label="Должность английский"
         required
         outlined
       />
    </div>
    <div v-if="vacantionSvgId">
      <change-s-v-g
          v-model="vacantionSvg">
      </change-s-v-g>
    </div>
    <div v-if="lang=='ru'">
       <v-text-field
         v-if="cityLabelId"
         v-model="cityLabelRu"
         label="Город русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
       <v-text-field
         v-if="cityLabelId"
         v-model="cityLabelEng"
         label="Город английский"
         required
         outlined
       />
    </div>
    <div v-if="citySvgId">
      <change-s-v-g
          v-model="citySvg">
      </change-s-v-g>
    </div>
    <div v-if="lang=='ru'">
       <v-text-field
         v-if="fileLabelId"
         v-model="fileLabelRu"
         label="Файл русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
       <v-text-field
         v-if="fileLabelId"
         v-model="fileLabelEng"
         label="Файл английский"
         required
         outlined
       />
    </div>
    <div v-if="fileSvgId">
      <change-s-v-g
          v-model="fileSvg">
      </change-s-v-g>
    </div>
    <div v-if="lang=='ru'">
       <v-text-field
         v-if="emailLabelId"
         v-model="emailLabelRu"
         label="email русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
       <v-text-field
         v-if="emailLabelId"
         v-model="emailLabelEng"
         label="email английский"
         required
         outlined
       />
    </div>
    <div v-if="emailSvgId">
      <change-s-v-g
          v-model="emailSvg">
      </change-s-v-g>
    </div>
    <div v-if="lang=='ru'">
       <v-text-field
         v-if="textLabelId"
         v-model="textLabelRu"
         label="Текст русский"
         required
         outlined
       />
    </div>
    <div v-if="lang=='en-US'">
       <v-text-field
         v-if="textLabelId"
         v-model="textLabelEng"
         label="Текст английский"
         required
         outlined
       />
    </div>
    <div v-if="textSvgId">
      <change-s-v-g
          v-model="textSvg">
      </change-s-v-g>
    </div>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>

<script>
import WorkForm from "@/components/ui/WorkForm.vue";
import ChangeSVG from "@/components/ui/ChangeContent/ChangeSVG.vue";

export default {
  name: "ChangeWorkForm",
  components: {ChangeSVG, WorkForm},
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'fioLabelRu': content_to_change['fioLabel']['value_rus'],
      'vacantionLabelRu': content_to_change['vacantionLabel']['value_rus'],
      'cityLabelRu': content_to_change['cityLabel']['value_rus'],
      'fileLabelRu': content_to_change['fileLabel']['value_rus'],
      'emailLabelRu': content_to_change['emailLabel']['value_rus'],
      'textLabelRu': content_to_change['textLabel']['value_rus'],

      'fioLabelEng': content_to_change['fioLabel']['value_eng'],
      'vacantionLabelEng': content_to_change['vacantionLabel']['value_eng'],
      'cityLabelEng': content_to_change['cityLabel']['value_eng'],
      'fileLabelEng': content_to_change['fileLabel']['value_eng'],
      'emailLabelEng': content_to_change['emailLabel']['value_eng'],
      'textLabelEng': content_to_change['textLabel']['value_eng'],

      'fioSvg': content_to_change['fioSvg']['value_eng'],
      'vacantionSvg': content_to_change['vacantionSvg']['value_eng'],
      'citySvg': content_to_change['citySvg']['value_eng'],
      'fileSvg': content_to_change['fileSvg']['value_eng'],
      'emailSvg': content_to_change['emailSvg']['value_eng'],
      'textSvg': content_to_change['textSvg']['value_eng']
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    fioLabelId () {
      return this.$store.getters['contentChanges/content_to_change']['fioLabel']['id_content']
    },
    vacantionLabelId () {
      return this.$store.getters['contentChanges/content_to_change']['vacantionLabel']['id_content']
    },
    cityLabelId () {
      return this.$store.getters['contentChanges/content_to_change']['cityLabel']['id_content']
    },
    fileLabelId () {
      return this.$store.getters['contentChanges/content_to_change']['fileLabel']['id_content']
    },
    emailLabelId () {
      return this.$store.getters['contentChanges/content_to_change']['emailLabel']['id_content']
    },
    textLabelId () {
      return this.$store.getters['contentChanges/content_to_change']['textLabel']['id_content']
    },
    fioSvgId () {
      return this.$store.getters['contentChanges/content_to_change']['fioSvg']['id_content']
    },
    vacantionSvgId () {
      return this.$store.getters['contentChanges/content_to_change']['vacantionSvg']['id_content']
    },
    citySvgId () {
      return this.$store.getters['contentChanges/content_to_change']['citySvg']['id_content']
    },
    fileSvgId () {
      return this.$store.getters['contentChanges/content_to_change']['fileSvg']['id_content']
    },
    emailSvgId () {
      return this.$store.getters['contentChanges/content_to_change']['emailSvg']['id_content']
    },
    textSvgId () {
      return this.$store.getters['contentChanges/content_to_change']['textSvg']['id_content']
    },
    fioLabelComputed() {
      if (this.$i18n.locale == 'ru') { return this.fioLabelRu }
      return this.fioLabelEng
    },
    vacantionLabelComputed() {
      if (this.$i18n.locale == 'ru') { return this.vacantionLabelRu }
      return this.vacantionLabelEng
    },
    cityLabelComputed() {
      if (this.$i18n.locale == 'ru') { return this.cityLabelRu }
      return this.cityLabelEng
    },
    fileLabelComputed() {
      if (this.$i18n.locale == 'ru') { return this.fileLabelRu }
      return this.fileLabelEng
    },
    emailLabelComputed() {
      if (this.$i18n.locale == 'ru') { return this.emailLabelRu }
      return this.emailLabelEng
    },
    textLabelComputed() {
      if (this.$i18n.locale == 'ru') { return this.textLabelRu }
      return this.textLabelEng
    },
  },
  methods: {
    saveChanges () {
      let valueList = []
      if (this.fioLabelId) {
        valueList.push({
          'value_rus': this.fioLabelRu === undefined ? '' : this.fioLabelRu,
          'value_eng': this.fioLabelEng === undefined ? '' : this.fioLabelEng,
          'id_content': this.fioLabelId
        })
      }
      if (this.vacantionLabelId) {
        valueList.push({
          'value_rus': this.vacantionLabelRu === undefined ? '' : this.vacantionLabelRu,
          'value_eng': this.vacantionLabelEng === undefined ? '' : this.vacantionLabelEng,
          'id_content': this.vacantionLabelId
        })
      }
      if (this.cityLabelId) {
        valueList.push({
          'value_rus': this.cityLabelRu === undefined ? '' : this.cityLabelRu,
          'value_eng': this.cityLabelEng === undefined ? '' : this.cityLabelEng,
          'id_content': this.cityLabelId
        })
      }
      if (this.fileLabelId) {
        valueList.push({
          'value_rus': this.fileLabelRu === undefined ? '' : this.fileLabelRu,
          'value_eng': this.fileLabelEng === undefined ? '' : this.fileLabelEng,
          'id_content': this.fileLabelId
        })
      }
      if (this.emailLabelId) {
        valueList.push({
          'value_rus': this.emailLabelRu === undefined ? '' : this.emailLabelRu,
          'value_eng': this.emailLabelEng === undefined ? '' : this.emailLabelEng,
          'id_content': this.emailLabelId
        })
      }
      if (this.textLabelId) {
        valueList.push({
          'value_rus': this.textLabelRu === undefined ? '' : this.textLabelRu,
          'value_eng': this.textLabelEng === undefined ? '' : this.textLabelEng,
          'id_content': this.textLabelId
        })
      }
      if (this.fioSvgId) {
        valueList.push({
          'value_rus': this.fioSvg === undefined ? '' : this.fioSvg,
          'value_eng': this.fioSvg === undefined ? '' : this.fioSvg,
          'id_content': this.fioSvgId
        })
      }
      if (this.vacantionSvgId) {
        valueList.push({
          'value_rus': this.vacantionSvg === undefined ? '' : this.vacantionSvg,
          'value_eng': this.vacantionSvg === undefined ? '' : this.vacantionSvg,
          'id_content': this.vacantionSvgId
        })
      }
      if (this.citySvgId) {
        valueList.push({
          'value_rus': this.citySvg === undefined ? '' : this.citySvg,
          'value_eng': this.citySvg === undefined ? '' : this.citySvg,
          'id_content': this.citySvgId
        })
      }
      if (this.fileSvgId) {
        valueList.push({
          'value_rus': this.fileSvg === undefined ? '' : this.fileSvg,
          'value_eng': this.fileSvg === undefined ? '' : this.fileSvg,
          'id_content': this.fileSvgId
        })
      }
      if (this.emailSvgId) {
        valueList.push({
          'value_rus': this.emailSvg === undefined ? '' : this.emailSvg,
          'value_eng': this.emailSvg === undefined ? '' : this.emailSvg,
          'id_content': this.emailSvgId
        })
      }
      if (this.textSvgId) {
        valueList.push({
          'value_rus': this.textSvg === undefined ? '' : this.textSvg,
          'value_eng': this.textSvg === undefined ? '' : this.textSvg,
          'id_content': this.textSvgId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES', {
        'valueList': valueList
      })
    }
  }
}
</script>

<style scoped>

</style>