<template>
  <div>
    <div style="height: 200px">
      <v-toolbar class="menu-toolbar">
        <div class="flex-grow-1"></div>
        <v-toolbar-items class="no-indentation">
          <v-btn-toggle class="menuBtnToggle">
            <icon-btn
              :submenu="submenuComputed"
              :svg-path="svgPath"
              :change="false">
            </icon-btn>
          </v-btn-toggle>
        </v-toolbar-items>
      </v-toolbar>
    </div>
    <div style="height: 200px">
      <div v-if="lang=='ru'">
        <v-text-field
                    v-if="submenuId"
                    v-model="submenuRu"
                    label="Текст русский"
                    required
                    outlined
            />
      </div>
      <div v-if="lang=='en-US'">
        <v-text-field
                    v-if="submenuId"
                    v-model="submenuEng"
                    label="Текст русский"
                    required
                    outlined
            />
      </div>
      <div v-if="svgPathId">
        <change-s-v-g
            v-model="svgPath">
        </change-s-v-g>
      </div>
      <v-btn @click="saveChanges">Сохранить изменения</v-btn>
    </div>
  </div>
</template>

<script>
import IconBtn from "@/components/ui/IconBtn.vue"
import ChangeSVG from '@/components/ui/ChangeContent/ChangeSVG.vue'

export default {
  name: "ChangeIconBtn",
  components: { IconBtn, ChangeSVG },
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'submenuRu': content_to_change['submenu']['value_rus'],
      'submenuEng': content_to_change['submenu']['value_eng'],
      'svgPath': content_to_change['svgPath']['value_rus']
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    submenuComputed () {
      if (this.$i18n.locale == 'ru') { return this.submenuRu }
      return this.submenuEng
    },
    svgPathComputed () {
      return this.svgPath
    },
    submenuId () {
      return this.$store.getters['contentChanges/content_to_change']['submenu']['id_content']
    },
    svgPathId () {
      return this.$store.getters['contentChanges/content_to_change']['svgPath']['id_content']
    }
  },
  methods: {
    saveChanges () {
      let valueList = []
      if (this.submenuId) {
        valueList.push({
          'value_rus': this.submenuRu === undefined ? '' : this.submenuRu,
          'value_eng': this.submenuEng === undefined ? '' : this.submenuEng,
          'id_content': this.submenuId
        })
      }
      if (this.svgPathId){
        valueList.push({
          'value_rus': this.svgPath === undefined ? '' : this.svgPath,
          'value_eng': this.svgPath === undefined ? '' : this.svgPath,
          'id_content': this.svgPathId
        })
      }
      console.log('valueList')
      console.log(valueList)
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES', {
        'valueList': valueList
      })
    }
  }
}
</script>