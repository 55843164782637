<template>
  <div>
    <div v-if="type === 'CardsWithNumbers'">
      <change-cards-with-numbers/>
    </div>
    <div v-if="type === 'CardManagement'">
      <change-card-management/>
    </div>
    <div v-if="type === 'Submenu'">
      <change-submenu/>
    </div>
    <div v-if="type === 'IconBtn'">
      <change-icon-btn/>
    </div>
    <div v-if="type === 'BlockDocuments'">
      <change-block-documents/>
    </div>
    <div v-if="type === 'ContentDocuments'">
      <change-content-document/>
    </div>
    <div v-if="type === 'CardLogo'">
      <change-card-logo/>
    </div>
    <div v-if="type === 'MainSliderSlide'">
      <change-main-slider-slide/>
    </div>
    <div v-if="type === 'ContentNumeric'">
      <change-content-numeric/>
    </div>
    <div v-if="type === 'ContentText'">
      <change-content-text/>
    </div>
    <div v-if="type === 'IconText'">
      <change-icon-text/>
    </div>
    <div v-if="type === 'TextHref'">
      <change-text-href/>
    </div>
    <div v-if="type === 'WorkForm'">
      <change-work-form/>
    </div>
    <div v-if="type === 'FeedbackForm'">
      <change-feedback-form/>
    </div>
    <div v-if="type === 'OfferGoodsForm'">
      <change-offer-goods-form/>
    </div>
    <div v-if="type === 'ReportForm'">
      <change-report-form/>
    </div>
    <div v-if="type === 'TooltipContent'">
      <change-tooltip-content/>
    </div>
    <div v-if="type === 'ContentFrame'">
      <change-content-frame/>
    </div>
    <div v-if="type === 'CardsWithSlogan'">
      <change-cards-with-slogan/>
    </div>
    <div v-if="type === 'PushLink'">
      <change-push-link/>
    </div>
    <div v-if="type === 'ImgContent'">
      <change-img-content/>
    </div>
    <div v-if="type === 'FooterImgLinks'">
      <change-footer-img-links/>
    </div>
    <div v-if="type === 'SVGLinkText'">
      <change-s-v-g-link-text/>
    </div>
    <div v-if="type === 'FooterIconText'">
      <change-footer-icon-text/>
    </div>
  </div>
</template>
<script>
import ChangeBlockDocuments from "@/components/ui/ChangeContent/ChangeBlockDocuments.vue";
import ChangeContentDocument from "@/components/ui/ChangeContent/ChangeContentDocument.vue";
import ChangeCardsWithNumbers from "@/components/ui/ChangeContent/ChangeCardsWithNumbers.vue";
import ChangeCardManagement from "@/components/ui/ChangeContent/ChangeCardManagement.vue";
import ChangeIconBtn from "@/components/ui/ChangeContent/ChangeIconBtn.vue";
import ChangeSubmenu from "@/components/ui/ChangeContent/ChangeSubmenu.vue";
import ChangeCardLogo from "@/components/ui/ChangeContent/ChangeCardLogo.vue";
import ChangeMainSliderSlide from "@/components/ui/ChangeContent/ChangeMainSliderSlide.vue";
import ChangeContentNumeric from "@/components/ui/ChangeContent/ChangeContentNumeric.vue";
import ChangeContentText from "@/components/ui/ChangeContent/ChangeContentText.vue";
import ChangeIconText from "@/components/ui/ChangeContent/ChangeIconText.vue";
import ChangeTextHref from "@/components/ui/ChangeContent/ChangeTextHref.vue";
import ChangeWorkForm from "@/components/ui/ChangeContent/ChangeWorkForm.vue";
import ChangeFeedbackForm from "@/components/ui/ChangeContent/ChangeFeedbackForm.vue";
import ChangeOfferGoodsForm from "@/components/ui/ChangeContent/ChangeOfferGoodsForm.vue";
import ChangeReportForm from "@/components/ui/ChangeContent/ChangeReportForm.vue";
import ChangeTooltipContent from "@/components/ui/ChangeContent/ChangeTooltipContent.vue";
import ChangeContentFrame from "@/components/ui/ChangeContent/ChangeContentFrame.vue";
import ChangeCardsWithSlogan from "@/components/ui/ChangeContent/ChangeCardsWithSlogan.vue";
import ChangePushLink from "@/components/ui/ChangeContent/ChangePushLink.vue";
import ChangeImgContent from "@/components/ui/ChangeContent/ChangeImgContent.vue";
import ChangeFooterImgLinks from "@/components/ui/ChangeContent/ChangeFooterImgLinks.vue";
import ChangeSVGLinkText from "@/components/ui/ChangeContent/ChangeSVGLinkText.vue";
import ChangeFooterIconText from "@/components/ui/ChangeContent/ChangeHtml/ChangeFooterIconText.vue";

export default {
  name: 'ChangeContent.vue',
  components: {
    ChangeFooterIconText,
    ChangeSVGLinkText,
    ChangeOfferGoodsForm,
    ChangeCardLogo,
    ChangeBlockDocuments,
    ChangeCardsWithNumbers,
    ChangeCardManagement,
    ChangeIconBtn,
    ChangeSubmenu,
    ChangeContentDocument,
    ChangeMainSliderSlide,
    ChangeContentNumeric,
    ChangeContentText,
    ChangeIconText,
    ChangeTextHref,
    ChangeWorkForm,
    ChangeFeedbackForm,
    ChangeReportForm,
    ChangeTooltipContent,
    ChangeContentFrame,
    ChangeCardsWithSlogan,
    ChangePushLink,
    ChangeImgContent,
    ChangeFooterImgLinks
  },
  mounted () {
    this.$nextTick(function () {
      this.value_list = JSON.parse(JSON.stringify(this.value_state))
    })
  },
  computed: {
    value_state () {
      return this.$store.getters["contentChanges/content"]
    },
    type () {
      return this.$store.getters["contentChanges/type_to_change"]
    },
    lang () {
      return this.$i18n.locale
    }
  },
  metaInfo: {
    meta: [
      { name: 'description',
        content: 'Изменение контента сайта' },
      { name: 'title',
        content: 'Изменение сайта'
      }
    ]
  }
}
</script>

<style scoped>

</style>
