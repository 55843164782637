<template>
  <div>
    <div>
      <text-href
          :text-before="textBeforeComputed"
          :text-after="textAfterComputed"
          :href-text = "hrefTextComputed"
          :change="false"
      />
    </div>
    <div v-if="lang=='ru'">
      <ChangeHtml
         v-if="textBeforeId"
         v-model="textBeforeRu"
      />
      <v-text-field
                  v-if="hrefTextId"
                  v-model="hrefTextRu"
                  label="Текст ссылки"
                  required
                  outlined
      />
      <ChangeHtml
         v-if="textAfterId"
         v-model="textAfterRu"
      />
    </div>
    <div v-if="lang=='en-US'">
      <ChangeHtml
         v-if="textBeforeId"
         v-model="textBeforeEng"
     />
      <v-text-field
                  v-if="hrefTextId"
                  v-model="hrefTextEng"
                  label="Текст ссылки"
                  required
                  outlined
      />
      <ChangeHtml
         v-if="textBeforeId"
         v-model="textAfterEng"
     />
    </div>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>
<script>
import TextHref from '@/components/ui/TextHref.vue'
import ChangeHtml from "@/components/ui/ChangeContent/ChangeHtml/ChangeHtml.vue";
export default {
  name: 'ChangeIconText',
  components: { TextHref, ChangeHtml },
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'textBeforeRu': content_to_change['textBefore']['value_rus'],
      'hrefTextRu': content_to_change['hrefText']['value_rus'],
      'textAfterRu': content_to_change['textAfter']['value_rus'],
      'textBeforeEng': content_to_change['textBefore']['value_eng'],
      'hrefTextEng': content_to_change['hrefText']['value_eng'],
      'textAfterEng': content_to_change['textAfter']['value_eng']
    }
  },
  computed: {
    textBeforeComputed () {
      if (this.$i18n.locale == 'ru') { return this.textBeforeRu }
      return this.textBeforeEng
    },
    hrefTextComputed () {
      if (this.$i18n.locale == 'ru') { return this.hrefTextRu }
      return this.hrefTextEng
    },
    textAfterComputed () {
      if (this.$i18n.locale == 'ru') { return this.textAfterRu }
      return this.textAfterEng
    },
    lang () {
      return this.$i18n.locale
    },
    textBeforeId () {
      return this.$store.getters['contentChanges/content_to_change']['textBefore']['id_content']
    },
    hrefTextId () {
      return this.$store.getters['contentChanges/content_to_change']['hrefText']['id_content']
    },
    textAfterId () {
      return this.$store.getters['contentChanges/content_to_change']['textAfter']['id_content']
    },
  },
  methods: {
    saveChanges () {
      let valueList = []
      if (this.textBeforeId) {
        valueList.push({
          'value_rus': this.textBeforeRu === undefined ? '' : this.textBeforeRu,
          'value_eng': this.textBeforeEng === undefined ? '' : this.textBeforeEng,
          'id_content': this.textBeforeId
        })
      }
      if (this.hrefTextId) {
        valueList.push({
          'value_rus': this.hrefTextRu === undefined ? '' : this.hrefTextRu,
          'value_eng': this.hrefTextEng === undefined ? '' : this.hrefTextEng,
          'id_content': this.hrefTextId
        })
      }
      if (this.textAfterId) {
        valueList.push({
          'value_rus': this.textAfterRu === undefined ? '' : this.textAfterRu,
          'value_eng': this.textAfterEng === undefined ? '' : this.textAfterEng,
          'id_content': this.textAfterId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES', {
        'valueList': valueList
      })
    }
  }
}
</script>