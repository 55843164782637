import { render, staticRenderFns } from "./ChangeFooterImgLinks.vue?vue&type=template&id=42bfd962&scoped=true&"
import script from "./ChangeFooterImgLinks.vue?vue&type=script&lang=js&"
export * from "./ChangeFooterImgLinks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42bfd962",
  null
  
)

export default component.exports