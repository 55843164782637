<template>
  <div>
    <div>
      <cards-with-slogan
          :title="titleComputed"
          :sub-title="subTitleComputed"
          :change="false"
      />
    </div>
    <div v-if="lang=='ru'" >
      <v-text-field
                v-if="titleId"
                v-model="titleRu"
                label="Заголовок русский"
                required
                outlined
        />
        <v-text-field
                v-if="subTitleId"
                v-model="subTitleRu"
                label="Подзаголовок русский"
                required
                outlined
        />
    </div>
    <div v-if="lang=='en-US'" >
      <v-text-field
                v-if="titleId"
                v-model="titleEng"
                label="Заголовок русский"
                required
                outlined
        />
        <v-text-field
                v-if="subTitleId"
                v-model="subTitleEng"
                label="Подзаголовок русский"
                required
                outlined
        />
    </div>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>
<script>
import CardsWithSlogan from '@/components/ui/CardsWithSlogan.vue';

export default {
  name: 'ChangeCardsWithSlogan',
  components: { CardsWithSlogan },
  data () {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'titleRu': content_to_change['title']['value_rus'],
      'subTitleRu': content_to_change['subTitle']['value_rus'],

      'titleEng': content_to_change['title']['value_eng'],
      'subTitleEng': content_to_change['subTitle']['value_eng']
    }
  },
  computed: {
    titleComputed () {
      if (this.$i18n.locale == 'ru') { return this.titleRu }
      return this.titleEng
    },
    subTitleComputed () {
      if (this.$i18n.locale == 'ru') { return this.subTitleRu }
      return this.subTitleEng
    },
    lang () {
      return this.$i18n.locale
    },
    titleId () {
      return this.$store.getters['contentChanges/content_to_change']['title']['id_content']
    },
    subTitleId () {
      return this.$store.getters['contentChanges/content_to_change']['subTitle']['id_content']
    }
  },
  methods: {
    saveChanges () {
      let valueList = []
      if (this.titleId) {
        valueList.push({
          'value_rus': this.titleRu === undefined ? '' : this.titleRu,
          'value_eng': this.titleEng === undefined ? '' : this.titleEng,
          'id_content': this.titleId
        })
      }
      if (this.subTitleId) {
        valueList.push({
          'value_rus': this.subTitleRu === undefined ? '' : this.subTitleRu,
          'value_eng': this.subTitleEng === undefined ? '' : this.subTitleEng,
          'id_content': this.subTitleId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES', {
        'valueList': valueList
      })
    }
  }
}
</script>

<style scoped>

</style>