<template>
  <div>
  <div>
    <Submenu
            :submenu=this.submenuComputed
            :svg-path=this.svgPathComputed
            :push=this.passMethod
            :change="false"
    >
    </Submenu>
  </div>
  <div v-if="lang=='ru'">
        <v-text-field v-if="submenuId"
                v-model="submenuRu"
                label="Текст русский"
                required
                outlined
        />
    </div>
    <div v-if="lang=='en-US'">
        <v-text-field
                v-if="submenuId"
                v-model="submenuEng"
                label="Текст английский"
                required
                outlined
        />
    </div>
    <div v-if="svgPathId">
      <change-s-v-g
          v-model="svgPath">
      </change-s-v-g>
    </div>
    <v-btn @click="saveChanges">Сохранить изменения</v-btn>
  </div>
</template>

<script>
import Submenu from '@/components/ui/Submenu.vue'
import ChangeSVG from '@/components/ui/ChangeContent/ChangeSVG.vue'

export default {
  name: "ChangeSubmenu",
  components: { ChangeSVG, Submenu },
  data() {
    let content_to_change = this.$store.getters['contentChanges/content_to_change']
    return {
      'submenuRu': content_to_change['submenu']['value_rus'],
      'submenuEng': content_to_change['submenu']['value_eng'],
      'svgPath': content_to_change['svgPath']['value_rus']
    }
  },
  computed: {
    submenuComputed () {
      if (this.$i18n.locale == 'ru') { return this.submenuRu }
      return this.submenuEng
    },
    svgPathComputed () {
      return this.svgPath
    },
    lang () {
      return this.$i18n.locale
    },
    submenuId () {
      return this.$store.getters['contentChanges/content_to_change']['submenu']['id_content']
    },
    svgPathId () {
      return this.$store.getters['contentChanges/content_to_change']['svgPath']['id_content']
    }
  },
  methods: {
    saveChanges () {
      let valueList = []
      if (this.submenuId) {
        valueList.push({
          'value_rus': this.submenuRu === undefined ? '' : this.submenuRu,
          'value_eng': this.submenuEng === undefined ? '' : this.submenuEng,
          'id_content': this.submenuId
        })
      }
      if (this.svgPathId) {
        valueList.push({
          'value_rus': this.svgPath === undefined ? '' : this.svgPath,
          'value_eng': this.svgPath === undefined ? '' : this.svgPath,
          'id_content': this.svgPathId
        })
      }
      this.$store.dispatch('contentChanges/SAVE_CONTENT_CHANGES', {
        'valueList': valueList
      })
    },
    passMethod(){
      return 'passMethod'
    }
  }
}
</script>
